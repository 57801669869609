export class Singleton extends EventTarget
{
    constructor()
    {
        super();

        this._abort = new AbortController();
        this.signal = this._abort.signal;

        // Use HMR to fire this.signal to allow the singleton to clean up on hot reload.
        if(import.meta.hot)
        {
            import.meta.hot.accept(() => {}); // to enable dispose
            import.meta.hot.dispose((data) => this._abort.abort());
        }
    }
}
