<template>
    <v-dialog v-model="dialogVisible" max-width="500px" @afterLeave="sendResult(null)">
        <v-card>
            <v-card-title class="headline" v-text=titleValue />
            <v-card-text>{{ textValue }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="sendResult(false)">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="sendResult(true)">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref } from 'vue';
const dialogVisible = ref(false);
const titleValue = ref("");
const textValue = ref("");
let sendResult = null;

defineExpose({
    showDialog: ({title="Confirmation", text}) =>
    {
        let promise = new Promise((resolve) => {
            titleValue.value = title;
            textValue.value = text;
            dialogVisible.value = true;
            sendResult = resolve;
        });
        promise.then(() => dialogVisible.value = false);
        return promise;
    },
});

</script>
