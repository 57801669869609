<template>
    <v-dialog
        :fullscreen="!smAndUp"
        :class="{ 'fullscreen': !smAndUp }"
        :width="smAndUp? '70vw':'100vw'"
        :height="smAndUp? '75vh':'100vh'" 
        min-width="70vw"
        v-model="modalVisible" 
    >
        <v-card 
            ref="dialogOuterContainer"
            class=outer-container :title=props.title prepend-icon="mdi-cog"
        >
            <div class=contents-container>
                <slot name=contents></slot>
            </div>
            <v-btn style="position: absolute; top: 10px; right: 10px;" class="ms-auto" icon="mdi-close" @click="modalVisible = false;"></v-btn>
        </v-card>
    </v-dialog>
</template>
    
<script setup>
import { ref, watchEffect, provide } from 'vue';
import { useDisplay } from 'vuetify'
const { smAndUp } = useDisplay();

import settings from "@/misc/settings.js";
const chatFontSize = settings.use("chatFontSize");
const modalVisible = ref(false);

// This is used by the settings dialog to dim the dialog and show the underlying chat.
const dialogOuterContainer = ref(null);
const overlayOpacity = ref(1);
provide("overlayOpacity", overlayOpacity);

const props = defineProps({
    title: { type: String },
    layer: { type: String },
});

watchEffect(() => {
    document.body.style.overflow = modalVisible.value ? 'hidden' : '';

    // CSS v-bind is broken with v-dialog (the v-dialog isn't inside the root of the template),
    // so just apply opacity manually.
    if(dialogOuterContainer.value)
        dialogOuterContainer.value.$el.closest(".v-overlay").style.opacity = overlayOpacity.value;
});

defineExpose({
    showDialog() { modalVisible.value = true; },
});
</script>

<style scoped>
.outer-container {
    margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    position: relative;
    /* height: 100%; */
    flex: 1;
}

.contents-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

:deep(.foo) {
    font-size: v-bind("chatFontSize.value + 'px'");
}

/* Fix the overlay background in dark mode brightening the background, which is the ugliest thing
 * in the known universe.  Why?! */
.v-overlay {
    --v-theme-on-surface: 0, 0, 0;
    --v-overlay-opacity: 0.5;
    transition: opacity 0.3s;
}

.v-overlay__content {
}

</style>
