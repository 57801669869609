// This handles the active connection to the server.

import settings from "@/misc/settings.js";
import GenerationBackendKobold from "@/ai/backend-kobold.js";
import GenerationBackendTextGen from "@/ai/backend-textgen.js";
import { Singleton } from "@/misc/singleton.js";
import { SavedSettingsLayer } from "@/misc/settings.js";

class Connection extends Singleton
{
    constructor()
    {
        super();
        let signal = this.signal;

        // Pretend we're connected at first, so we don't flash the disconnection icon
        // on page load before we've finished checking.
        this._connected = true;
        this._lastCheckedUrl = null;

        window.addEventListener("focus", () => this._onFocus(), { signal });
        settings.addEventListener("serverUrl", () => {
            if(this._lastCheckedUrl == settings.values.serverUrl)
                return;
            this._lastCheckedUrl = settings.values.serverUrl;

            this._checkConnection({ restart: true });
        }, { signal });

        this._checkConnection();
    }
    
    get connected() { return this._connected; }

    // Check the connection.
    //
    // If restart is true and a check is already running, cancel it and start a new one.
    async _checkConnection({ restart }={})
    {
        if(this._checkingConnection)
        {
            if(!restart)
                return;

            this._checkConnectionAbort.abort();
            await this._checkingConnection;
        }

        this._checkPending = false;
        let promise = this._checkConnectionInner();
        try {
            this._checkingConnection = promise;
            await promise;
        } finally {
            if(promise == this._checkingConnection)
                this._checkingConnection = null;
        }
    }

    async _checkConnectionInner()
    {
        this._checkConnectionAbort = new AbortController();
        let signal = this._checkConnectionAbort.signal;

        let backend = this.getBackend();
        let modelName = await backend.getModelName({ signal });
        if(signal.aborted)
            return;

        let connected = modelName != null;
        if(this._connected == connected)
            return;

        this._connected = connected;
        this.dispatchEvent(new Event("connection-changed"));

        if(this._connected)
        {
            // Load the settings layer for this model.
            settings.modelLayer = SavedSettingsLayer.loadTemplate(modelName);
            console.log(`Connected to ${backend.url}, model: ${modelName}`);
        }
        else
        {
            console.log(`Not connected :${backend.url}`);
        }
    }

    async _onFocus()
    {
        this._checkConnection();
    }

    // Return a connection backend.
    //
    // We currently only have one, but this centralizes creating it.
    getBackend()
    {
        return new GenerationBackendTextGen();
        // return new GenerationBackendKobold();
    }

}

const singleton = new Connection();
export default singleton;