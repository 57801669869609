// A wrapper around Dexie to give a simple object store.  We use a separate database
// for each type.
export default class ObjectDb extends EventTarget
{
    getDb() { throw new Error("Not implemented"); }
    get entryClass() { throw new Error("Not implemented"); }

    constructor()
    {
        super();

        this._items = {};
    }

    async open()
    {
        let db = this.getDb();
        await db.open();
        return db;
    }

    // XXX: use a BroadcastChannel to reload?
    async load()
    {
        this._items = await this._getAllFromDb();
        // console.log("Loaded:", Object.keys(this._items).length);
    }

    // Return all saved entries.
    async _getAllFromDb()
    {
        let table = await this.getTable();
        let results = { };
        for(let data of await table.toArray())
        {
            let entry = new this.entryClass(data);
            results[entry.id] = entry;
        }

        return results;
    }

    // Return the underlying data for the given entry.  This won't create the model object.
    async getRawById(id)
    {
        let table = await this.getTable();
        return await table.get(id);
    }

    getAll() { return Object.values(this._items); }

    getById(id)
    {
        return this._items[id];
    }

    // Return all IDs.  IDs will be sorted, grouping characters in the same
    // group together.
    getAllIds()
    {
        let ids = Object.keys(this._items);
        ids.sort();
        return ids;
    }

    async save(entry)
    {
        let table = await this.getTable();
        let isNew = entry.id == null;

        entry.id = await table.put(entry.toJSON());
        this._items[entry.id] = entry;

        if(isNew)
            this._dispatchListChanged();

        let e  = new Event("entry-saved");
        e.id = entry.id;
        this.dispatchEvent(e);
    }

    async delete(id)
    {
        delete this._items[id];

        let table = await this.getTable();
        await table.delete(id);
        this._dispatchListChanged();
    }

    // entry-list-changed is sent when items are added or removed from the list.
    _dispatchListChanged()
    {
        this.dispatchEvent(new Event("entry-list-changed"));
    }
}
