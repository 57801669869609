<template>
    <v-card density=compact>
        <v-card-actions>
            <v-icon
                id="modified-icon"
                class="modified-icon"
                ref="modifiedIcon"
                :class="{
                    'modified': settingHasValue,
                    'overridden': modifiedByOtherLayer != null,
                }"
                :icon="modifiedByOtherLayer? 'mdi-alert':'mdi-cog'"
            />

            <v-tooltip
                :activator=modifiedIcon
                :text=modifiedByOtherLayer
                location="top"
                :disabled="modifiedByOtherLayer == null"
            >
            </v-tooltip>

            <v-menu
                :activator=modifiedIcon
                v-if="settingHasValue"
            >
                <v-list density="compact">
                    <v-list-item
                        @click="() => resetSetting()"
                    >
                        <template v-slot:append>
                            <v-icon icon="mdi-delete" />
                        </template>

                        <v-list-item-title>Reset Setting</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-card-text
                class=label
                :class="{ 'modified': settingHasValue }"
                v-text="settingDef.desc"
            />

            <v-slider
                v-if="settingDef.type == 'number'"
                v-model="value"  :readonly="readonly"
                style="flex: 1 1; max-width: 50%;"
                @update:modelValue="valueChanged"
                @start="startedDragging(settingDef)"
                @end="stoppedDragging(settingDef)"
                :min=settingDef.min :max=settingDef.max :step=settingDef.step hide-details
            />

            <v-text-field
                v-if="settingDef.type == 'number'"
                v-model="value" :readonly="readonly"
                density="compact"
                @blur="valueChanged(value)"
                style="width: 80px; flex-grow: 0; padding-left: .5em;"
                type="number"
                variant="outlined"
                hide-details
                hide-spin-buttons
            />

            <v-switch
                v-if="settingDef.type == 'boolean'"
                v-model="value"  :readonly="readonly"
                @update:modelValue="valueChanged"
                hide-details
            />

            <v-select 
                v-if="props.setting == 'instructTemplate'"
                v-model="value"  :readonly="readonly"
                density=compact
                @update:modelValue="valueChanged"
                hide-details
                item-text="text"
                style="min-width: 200px;"
                :items="instructTemplateNames"
            >
                <template v-slot:item="{ item, props }">
                    <v-list-item v-bind="props">
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item>
                </template>

                <template v-slot:prepend-item>
                    <v-btn text prepend-icon="mdi-pencil" @click="createNewModelTemplate({copy: false})">New</v-btn>
                    <v-btn text prepend-icon="mdi-content-copy" @click="createNewModelTemplate({ copy: true })">Save as</v-btn>
                    <v-btn text prepend-icon="mdi-delete" @click="deleteModelTemplate()">Delete</v-btn>
                </template>
            </v-select>
        </v-card-actions>

        <v-text-field
            v-if="settingDef.type == 'string' && !settingDef.multiline"
            v-model="value"  :readonly="readonly"
            @blur="valueChanged(value)"
            single-line
        />
        <v-textarea
            v-if="settingDef.type == 'string' && settingDef.multiline"
            v-model="value"  :readonly="readonly"
            variant="outlined"
            auto-grow
            rows=1
            @blur="valueChanged(value)"
        />

        
    </v-card>
</template>
    
<script setup>
import { ref, watch, inject, onUnmounted } from 'vue';
import settings from "../misc/settings.js";

const props = defineProps({
    setting: { type: String },
    layer: { type: String },
    readonly: { type: Boolean, default: false },
});

const overlayOpacity = inject("overlayOpacity");

const value = ref(null);
const settingDef = ref(null);
const modifiedByOtherLayer = ref(null);
const settingHasValue = ref(false);
const modifiedIcon = ref(null);
const instructTemplateNames = ref([]);

import InstructTemplate from "@/ai/instruct-template.js";
if(props.setting == 'instructTemplate')
    instructTemplateNames.value = InstructTemplate.getAllTemplateNames();

function currentLayer()
{
    return settings.getLayer(props.layer.toLowerCase());
}

function loadValue()
{
    settingDef.value = settings.getDefinition(props.setting);

    // settingHasValue is true if this setting is modified by this layer.
    let layer = currentLayer();
    settingHasValue.value = layer.has(props.setting);

    // The value set by this layer.  If we don't set a value, we'll show the value
    // up until this layer.
    value.value = settings.getValue(props.setting, {
        startAtLayer: props.layer
    });

    // Check if any other layers affect this setting.
    let laterLayersModifyValue = settings.laterLayersThatOverride(props.setting, props.layer);
    let modified = settingHasValue.value? "Also modified in:": "Modified in:";
    if(laterLayersModifyValue.length > 0)
        modifiedByOtherLayer.value = `${modified}: ${laterLayersModifyValue.join(", ")}`;
    else
        modifiedByOtherLayer.value = null;
}
loadValue();

function resetSetting()
{
    currentLayer().unset(props.setting);
    loadValue();
}

// Save the value to the setting when it changes.  Note that we don't use a watch for this,
// since we don't want this to happen when resetSetting() removes the value.
function valueChanged(value)
{
    currentLayer().set(props.setting, value);
}

// Refresh if our layer changes.
watch(props, (newLayer, oldLayer) => loadValue());

// Refresh if the setting changes, such as due to the model template changing.
settings.watch(props.setting, () => loadValue());

// If we're editing a dimDialog value, reduce the dialog opacity during drags.
function startedDragging(settingDef)
{
    if(settingDef.dimDialog)
        overlayOpacity.value = 0.1;
}

function stoppedDragging()
{
    overlayOpacity.value = 1;
}

onUnmounted(() => {
    stoppedDragging();
});
</script>

<style scoped>
:deep(.v-card-text) {
    flex-grow: 0;
}

:deep(.v-card-actions) {
    min-height: 0 !important;
    padding: 0;
}

:deep(.v-field) {
    --v-input-padding-top: 0px !important;
    --v-input-padding-bottom: 0px !important;
    --v-field-padding-top: 0px !important;
    --v-field-padding-bottom: 0px !important;
    --v-input-control-height: 30px !important;
}

.label
{
    flex: .5;
    color: #aaa;
    &.modified
    {
        color: #fff;
        
    }
}

.modified-icon
{
    visibility: hidden;
    &.modified, &.overridden
    {
        visibility: visible;
    }

    &.modified
    {
        cursor: pointer;
    }

}
</style>
