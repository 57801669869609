<template>
    <v-app full-height>
        <!-- Dialogs -->
        <ConfirmationDialog ref="confirmationDialog" />
        <TextEntryDialog ref="textEntryDialog" />
        <SettingsDialog ref=settingsDialog />

        <v-snackbar v-model="snackbarVisible" :color="snackbarColor" :timeout="2500">
            {{ snackbarMessage }}
        </v-snackbar>

        <!-- Sidebar: -->
        <v-navigation-drawer
            v-model="chatsDrawerVisible"
            class=navigation-drawer
            disable-route-watcher
            :touchless=false
            temporary
        >
            <div ref=drawerHelper hidden></div>
            <ChatListView />
        </v-navigation-drawer>

        <!-- Main view -->
        <v-main style="height: 100vh">
            <!-- :key makes RouterView create a new component if the path changes, instead
                 of reusing the view. -->
            <RouterView :key="$route.fullPath" />
        </v-main>
    </v-app>
</template>

<script setup>
import { ref, shallowRef, provide, onMounted } from 'vue';
import { RouterView } from 'vue-router'
import ConfirmationDialog from '@/views/dialogs/ConfirmationDialog.vue';
import TextEntryDialog from '@/views/dialogs/TextEntryDialog.vue';
import ChatListView from '@/views/ChatListView.vue'
import SettingsDialog from '@/views/SettingsDialog.vue'

const chatsDrawerVisible = ref(false);
const drawerHelper = ref(null);
const drawerIsMobile = ref(false);

provide('chatsDrawerVisible', chatsDrawerVisible);

// Dialogs:
const confirmationDialog = ref(null);
provide('showConfirmationDialog', ({title, text}) => confirmationDialog.value.showDialog({title, text}));

const textEntryDialog = ref(null);
provide('showTextEntryDialog', (args) => textEntryDialog.value.showDialog(args));

const settingsDialog = ref(null);
provide('showSettingsDialog', (args) => settingsDialog.value.showDialog(args));

// The chat loaded into the Chat view.
const activeChat = shallowRef(null);
provide('activeChat', activeChat);

// Notifications:
const snackbarVisible = ref(false);
const snackbarMessage = ref('');
const snackbarColor = ref('success');

import style from '@/assets/code-highlight-themes/vs2015.css?inline';
let styleNode = document.createElement('style');
styleNode.innerHTML = style;
document.head.appendChild(styleNode);

// let styleName = 'a11y-dark';
// let style = await require(`highlight.js/styles/${styleName}.css`);
// console.log(style);

provide('showMessage', (message, color='success') =>
{
    snackbarMessage.value = message;
    snackbarColor.value = color;
    snackbarVisible.value = true;
});

onMounted(() => {
    // We need to show the button to collapse the navigation bar on desktop, but
    // not on mobile where it auto-collapses, but there's no property to tell us
    // whether it's decided to be in mobile mode or not.  Work around this by checking
    // the drawer for the v-navigation-drawer--mobile class.
    //
    // Find the v-navigation-drawer container.  For some reason using ref on the
    // v-navigation-drawer itself gives us a random text node, so we have to hunt
    // for it.
    let drawer = drawerHelper.value.closest(".v-navigation-drawer");

    let updateDrawerState = () =>drawerIsMobile.value = drawer.classList.contains("v-navigation-drawer--mobile");
    let observer = new MutationObserver(() => updateDrawerState());
    observer.observe(drawer, { attributes: true });
});

</script>

<style scoped>
.navigation-drawer
{
    background-color: rgb(var(--v-theme-background));

    padding-top: env(safe-area-inset-top);
}

:deep(.v-navigation-drawer__scrim) { opacity: 0; }

.overlay-button {
    position: fixed;
    bottom: 10px;
    background-color: rgba(0,0,0,0.5);
    /* color: #aaa; */
    &:hover { color: #fff; }
    left: 0px;
}

.settings-scroller {
    max-height: 70vh;
    overflow-y: hidden;
    padding: 0px 50px;
}
</style>