import * as Util from '@/misc/util';

import rehypeStringify from 'rehype-stringify'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks';
import rehypeHighlight from 'rehype-highlight'
import {unified} from 'unified'

// Add source offsets to text nodes.
function addTopTokens({ topTokensList })
{
    return (tree) =>
    {
        if(!topTokensList)
            return;

        // Make a list of nodes to traverse.  This is done rather than using the iterator directly
        // so we don't traverse nodes that we create ourselves, which can get slow since we create
        // lots of tiny spans.
        let nodes = Array.from(visitRemarkNodes(tree));
        for(let [node, index, parent] of nodes)
        {
            if(node.type != "text")
            {
                continue;
            }

            if(!node.position)
                continue;

            // Break this string based on the available topTokens.  topTokens is a dictionary
            // from offsets to strings and alternative tokens:
            // {
            //     1: { token: "Hel", topTokens },
            //     4: { token: "lo", topTokens },
            // }
            //
            // If our string is "Hello there", we'll break it into <span>Hel</span> and
            // <span>lo</span> so we can store the topTokens for each piece.
            let spans = [];
            let buffer = "";
            for(let idx = 0; idx < node.value.length; )
            {
                // idx is the position within this node, offset is the overall position within the
                // source markdown.
                let offset = node.position.start.offset + idx;
                let tokensAtIdx = topTokensList.get(offset);

                // If there's no token info for this character, add it to the buffer.
                if(tokensAtIdx == null)
                {
                    buffer += node.value[idx];
                    idx++;
                    continue;
                }

                // We have token info.  Check that the token in the list matches the text we have.
                // If it doesn't match then ignore the top tokens and just add it to the buffer.  This
                // can happen with markdown, where the input will have tokens that have turned into
                // markup when we get here.
                let { token, topTokens } = tokensAtIdx;
                let actualToken = node.value.substr(idx, token.length);
                if(token != actualToken)
                {
                    // console.log(`topTokenList mismatch: expected "${token}", got "${actualToken}"`);
                    buffer += node.value[idx];
                    idx++;
                    continue;
                }

                // Flush any text in the buffer into its own span.
                if(buffer.length > 0)
                {
                    spans.push({ type: "text", value: buffer });
                    buffer = "";
                }

                spans.push({
                    type: "element",
                    tagName: "span",
                    properties: {
                        "data-logprobs": JSON.stringify(topTokens),
                        "data-offset": offset,
                    },
                    children: [{ type: "text", value: token }],
                });

                idx += token.length;
            }

            // Flush any remaining text.
            if(buffer.length > 0)
                spans.push({ type: "text", value: buffer });

            parent.children[index] = {
                type: "element",
                tagName: "span",
                properties: {
                    "data-offset": node.position.start.offset,
                },
                children: spans,
            };
        }
    };
}

function *visitRemarkNodes(node)
{
    if(!node.children)
        return;

    for(let index in node.children)
    {
        let skipChildren = [false];
        let child = node.children[index];
        yield [child, index, node];

        for(let child of node.children) {
            yield *visitRemarkNodes(child);
        }
    }
}

function markdownToHtml(markdown, topTokensList)
{
    const stringify = unified().
        use(remarkParse).
        use(remarkGfm).
        use(remarkBreaks).
        use(remarkRehype).
        use(addTopTokens, {
            topTokensList
        }).
        use(rehypeHighlight, {
            detect: true,
            subset: [
                "plaintext", "python", "javascript", "typescript", "json", "html", "css", "xml",
            ],
        }).
        use(rehypeStringify);

    let { value: html } = stringify.processSync(markdown);

    let formattedNode = document.createElement("div");
    formattedNode.innerHTML = html;

    // "quotes" -> <q>"quotes"</q>
    Util.wrapQuotesWithQ(formattedNode);

    return formattedNode;
}

// Format a list of messages and add it to the DOM container.
export function messagesToDom(messages, container)
{
    for(let message of messages)
    {
        let div = messageToDom(message);
        container.appendChild(div);
    }
}

export function messageToDom(message)
{
    let { role, content, ongoing, purged, editing, topTokens } = message;

    let div = document.createElement("div");
    div.dataset.type = "message";
    div.dataset.role = role;
    if(ongoing)
        div.dataset.ongoing = "ongoing";
    if(purged)
        div.dataset.purged = "purged";
    if(editing)
        div.classList.add("editing");

    let buttonStrip = document.createElement("div");
    buttonStrip.classList.add("buttons");
    buttonStrip.classList.add("show-with-cursor");
    div.appendChild(buttonStrip);

    // Gear icon:
    let gearNode = document.createElement("span");
    gearNode.classList.add("gear");
    buttonStrip.appendChild(gearNode);

    // Edit icon:
    // let editNode = document.createElement("span");
    // editNode.classList.add("edit");
    // buttonStrip.appendChild(editNode);

    // Character list for DreamGen:
    let messageSenderNode = document.createElement("span");
    messageSenderNode.classList.add("message-sender");

    let displayNamesNode = document.createElement("span");
    displayNamesNode.classList.add("display-names");
    messageSenderNode.appendChild(displayNamesNode);

    let displayNames = Util.roleToDisplayNames(role);
    displayNames = displayNames.join(", ");
    if(displayNames == "")
        displayNames = "Narrator";  // for DreamGen text with no characters
    displayNamesNode.textContent = `${displayNames}: `;
    div.appendChild(messageSenderNode);

    // Formatted text:
    let formattedNode = markdownToHtml(content ?? "", topTokens);
    formattedNode.classList.add("formatted");

    // Make sure we have at least one blank line.
    if(formattedNode.firstChild == null)
        formattedNode.innerHTML = "<br>";

    // formattedNode.innerHTML = html;
    div.appendChild(formattedNode);

    // Editor text:
    let editorNode = document.createElement("div");
    editorNode.classList.add("editor");
    editorNode.contentEditable = "plaintext-only";
    editorNode.textContent = content;
    div.appendChild(editorNode);

    return div;
}

/*
// Convert the editor representation back to a message list.  This should always round-trip.
export function domToMessages(root)
{
    let messages = [];

    for(let messageNode of root.children)
    {
        if(messageNode.dataset.role == "system")
            continue;

        let message = domToMessage(messageNode);
        messages.push(message);
    }

    return { messages };
}

// Convert a single DOM message node to aconversation message entry.
function domToMessage(messageNode)
{
    let role = messageNode.dataset.role;
    let ongoing = messageNode.dataset.ongoing ?? false;
    let purged = messageNode.dataset.purged ?? false;

    // Pull the original markdown out of the editor node.
    let editorNode = messageNode.querySelector(".editor");
    let content = editorNode.textContent;

    return { role, ongoing, purged, content };
}
*/
