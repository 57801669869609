// High-level utility effects.

import { inject, watch } from 'vue';
import { reactive } from 'vue';
import InstructTemplate from "@/ai/instruct-template.js";
import worldInfo from "@/storytelling/characters.js";
import characterDb from "@/storytelling/characters.js";
import { chatDb } from '@/misc/chat'
import { useEventListener } from '@vueuse/core'

// Return a reactive reference to the available character names (roles) for the
// current chat.
export function useCharacterNames()
{
    const characterNames = reactive({ value: [] });

    // Get the active chat from Chat.vue.
    const activeChat = inject('activeChat');

    function loadNames()
    {
        // If we're not in DreamGen mode, the only roles are "user" and "assistant".
        let instructTemplate = InstructTemplate.getActiveTemplate();
        if(activeChat.value == null || !instructTemplate?.dreamGen)
        {
            characterNames.value = ["user", "assistant"];
            return;
        }

        // Get the list of characters from the chat.
        let characterNameList = [];
        for(let characterId of activeChat.value.characters)
        {
            let character = characterDb.getById(characterId);
            characterNameList.push(character?.name ?? "(missing)");
        }

        characterNames.value = characterNameList;
    }
    loadNames();

    // Refresh if anything changes that might change the role list:
    watch(activeChat, () => loadNames());
    watch(InstructTemplate.activeTemplateRef(), () => loadNames());
    useEventListener(worldInfo, "saved", (e) => loadNames())
    useEventListener(chatDb, "entry-saved", (e) => loadNames())

    return characterNames;
}

export function useScrolledDown(scroller)
{
    let value = reactive({ value: false });
    let lastTopPos = 0;

    useEventListener(scroller, "scroll", (e) => {
        let y = scroller.value.scrollTop;

        // Ignore scrolls past the edge of the content, so we don't change scroll
        // directions due to overscroll bounce.
        if(y <= 0 || y + scroller.value.offsetHeight >= scroller.value.scrollHeight)
            return;

        let delta = y - lastTopPos;
        lastTopPos = y;
        if(delta == 0)
            return;

        // Check if we're scrolling up or down.
        value.value = delta > 0;
    });

    return value;
}
