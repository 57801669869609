<template>
    <div class=top-container>
        <div class=bottom-bar>
            <!-- The response character selector is only shown while in DreamGen mode. -->
            <CharacterSelector class=character-selector v-show="isDreamGen.value" />

            <div class=button-strip>
                <v-btn density=compact size=x-large variant=plain icon=mdi-list-box-outline @click.stop="chatsDrawerVisible = !chatsDrawerVisible;" />
                <v-btn density=compact size=x-large variant=plain icon=mdi-undo @click.stop="undo"
                    :disabled="props.isGenerating || !props.canUndo"id="menu-button" />
                <v-btn density=compact size=x-large variant=plain icon=mdi-redo @click.stop="redo"
                    :disabled="props.isGenerating || !props.canRedo" />
                <v-btn density=compact size=x-large variant=plain  icon=mdi-refresh @click.stop="retryGeneration"
                    :disabled="props.isGenerating" />
                <!-- <v-btn density=compact size=x-large variant=plain :disabled="props.isGenerating" icon=mdi-menu class=menu-button /> -->
                <v-btn density=compact size=x-large variant=plain icon=mdi-cog @click.stop="showSettingsDialog();" />
                <v-btn density=compact size=x-large variant=plain v-if="!props.isGenerating && connected"
                    :icon="showContinueIcon? 'mdi-plus-circle':'mdi-arrow-right-circle'"
                    @click.stop="beginGeneration" />
                <v-btn density=compact size=x-large variant=plain v-if="props.isGenerating && connected" icon=mdi-stop-circle @click.stop="stopGeneration" />

                <v-tooltip
                    text="No connection to server"
                    location="top"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props"
                            v-if="!connected"
                            density=compact size=x-large variant=plain icon=mdi-cloud-off
                            class="offline"
                            @click.stop="showSettingsDialog('server')"
                        />
                    </template>
                </v-tooltip>
            </div>
        </div>

        <!--
        <v-menu activator=".menu-button">
            <v-list density="compact">
                <v-list-item @click="addAssistantMessage">
                    <v-list-item-title>Add as assistant</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    -->
    </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import CharacterSelector from './CharacterSelector.vue';
import InstructTemplate from "@/ai/instruct-template.js";
import Connection from '@/ai/connection'

const props = defineProps({
    isGenerating: { type: Boolean },
    canUndo: { type: Boolean },
    canRedo: { type: Boolean },
    showContinueIcon: { type: Boolean }, // the latest message can be continued, so show the + icon instead
});

const emit = defineEmits([
    "beginGeneration",
    "stopGeneration",
    "retryGeneration",
    "undo",
    "redo",
]);

const chatsDrawerVisible = inject('chatsDrawerVisible');
const showSettingsDialog = inject('showSettingsDialog');
const isDreamGen = InstructTemplate.dreamGenRef();

const connected = ref(false);
connected.value = Connection.connected;
Connection.addEventListener("connection-changed", (event) => connected.value = Connection.connected);

function beginGeneration() { emit("beginGeneration"); }
function stopGeneration() { emit("stopGeneration"); }
function retryGeneration() { emit("retryGeneration"); }
function undo() { emit("undo"); }
function redo() { emit("redo"); }

</script>

<style scoped>
.bottom-bar
{
    display: flex;
    flex: 1;
    border-top: solid 1px #1e2231;
    margin-bottom: -5px;
}

.top-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 4px;

    /* padding: 10px 20px; */
    /* padding-bottom: 10px; */
    border: solid 1px #1e2231;
    border-bottom: none;
    background: rgba(var(--v-theme-background-darken-1), 0.8);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    /* border-radius: 20px 20px 0 0; */
    border-radius: 20px;
    overflow: hidden;

    .character-selector {
        width: 250px;
        flex-grow: 1000;
    }

    .button-strip {
        display: flex;
        .v-btn {
            /* Slightly taller for mobile, but don't space them out too much. */
            height: calc(var(--v-btn-height) + 0px);
        }

        .offline {
            color: #ff0;
            opacity: 1;
            &:hover { color: #ff8; }
        }
    }

    .input-box {
        display: block;
        width: 100%;
        font-size: 24px;
        max-height: 40vh;
        color: #fff;
        /* outline: none; */
        resize: none;
        background: none;
        margin: 0 10px;

        /* https://vueuse.org/core/useTextareaAutosize/ says we should "reset" the
         * scrollbar styles for it to work, but what it actually shows to do is hide
         * it completely.  It doesn't actually set the size correctly and they want
         * everyone to just hide the scrollbar.  Oh well, I'm tired of fighting with it. */
        /* useTextareaAutosize */
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar { display: none; }
    }
}
</style>
