<template>
    <v-dialog v-model="dialogVisible" max-width="500px" @afterLeave="sendResult(null)">
        <v-form @submit.prevent="sendResult(value)">
            <v-card>
                <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>
                <v-card-text v-if="message">{{ message }}</v-card-text>
                <v-spacer></v-spacer>

                <v-card-actions>
                        <v-text-field autofocus v-model="value" label="Document Name" single-line></v-text-field>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="sendResult(null)">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="sendResult(value)">Confirm</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script setup>
import { ref } from 'vue';
const dialogVisible = ref(false);
const title = ref("");
const message = ref("");
const value = ref("");
let sendResult = null;

defineExpose({
    showDialog: ({
        title: newTitle,
        message: newMessage,
        oldValue,
    }={}) =>
    {
        let promise = new Promise((resolve) => {
            title.value = newTitle;
            message.value = newMessage;
            value.value = oldValue;
            dialogVisible.value = true;
            sendResult = resolve;
        });
        promise.then(() => dialogVisible.value = false);
        return promise;
    },
});

</script>
