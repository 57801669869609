<template>
    <div style="display: flex; flex-wrap: wrap;">
        <v-card-text>
            Instruct templates can be assigned to models on the model tab.  To modify
            a built-in template, first make a copy.
        </v-card-text>

    </div>

        <div style="display: flex; flex-wrap: wrap; align-items: center;">

        <!-- Editing buttons -->
        <v-btn text prepend-icon="mdi-pencil" @click="createNewModelTemplate({copy: false})">New</v-btn>
        <v-btn text prepend-icon="mdi-content-copy" @click="createNewModelTemplate({ copy: true })">Save as</v-btn>
        <v-btn text prepend-icon="mdi-delete" @click="deleteModelTemplate()" :disabled="readonly">Delete</v-btn>

        <!-- Template dropdown -->
        <v-select 
            ref=dropdown
            density=compact
            hide-details
            label="Template"
            v-model="instructTemplateName"
            style="width: 200px;"
            :items="dropdownTemplates"
            variant="underlined"

        >
            <template v-slot:item="{ item, props }">
                <v-list-item v-bind="props">
                </v-list-item>
            </template>
        </v-select>
    </div>
    
    <!-- Setting list -->
    <v-card
        v-for="(item, index) in instructTemplateSettings" :key="index"
        density=compact
        v-show="item.field == 'dreamGen' || !hideNonDreamGen"
    >
        <v-checkbox
            v-if="item.type == 'boolean'"
            v-model="values[index]"
            :label="item.label"
            :disabled="readonly"
        />

        <v-card-text class=label v-text="item.label" v-if="item.type != 'boolean'" />

        <v-textarea
            v-if="item.type == 'string'"
            v-model="values[index]"
            variant="outlined"
            auto-grow
            rows=1
            :disabled="readonly"
        />

    </v-card>
</template>
    
<script setup>
import { ref, shallowRef, inject, watch, onMounted } from 'vue';

import InstructTemplate from "@/ai/instruct-template.js";
import settings from "@/misc/settings.js";

const dialog = ref(null);
const showTextEntryDialog = inject('showTextEntryDialog');
const showMessage = inject('showMessage');
const showConfirmation = inject('showConfirmationDialog');

const instructTemplate = new shallowRef(null);
const instructTemplateName = new ref("");
const values = ref([]);
const dropdown = ref(null);
const dropdownTemplates = ref([]);
const readonly = ref(false);
const hideNonDreamGen = ref(false);

const instructTemplateSettings = [
    { type: "boolean", field: "dreamGen", label: "DreamGen" },
    { type: "string", field: "systemPrefix", label: "System prompt prefix" },
    { type: "string", field: "systemSuffix", label: "System prompt suffix" },
    { type: "string", field: "userPrefix", label: "User prompt prefix" },
    { type: "string", field: "userSuffix", label: "User prompt suffix" },
    { type: "string", field: "assistantPrefix", label: "Assistant prompt prefix" },
    { type: "string", field: "assistantSuffix", label: "Assistant prompt suffix" },
    { type: "string", field: "stopSequence", label: "Stop sequence" },
];

instructTemplateName.value = settings.values.instructTemplate;
instructTemplate.value = InstructTemplate.getTemplateByName(settings.values.instructTemplate);

const props = defineProps({
    currentTab: { type: String },
});

// Load the instruct template into the form.
function loadValues()
{
    instructTemplate.value = InstructTemplate.getTemplateByName(instructTemplateName.value);
    readonly.value = instructTemplate.value.readonly;
    for(let [key, { field }] of Object.entries(instructTemplateSettings))
        values.value[key] = instructTemplate.value[field];

    hideNonDreamGen.value = instructTemplate.value.dreamGen;
}
loadValues();

// Save the form back to the instruct template.
function saveValues()
{
    if(instructTemplate.value.readonly)
        return;

    for(let [key, { field }] of Object.entries(instructTemplateSettings))
        instructTemplate.value[field] = values.value[key];
    instructTemplate.value.save();

    hideNonDreamGen.value = instructTemplate.value.dreamGen;
}
watch(instructTemplateName, () => loadValues());
watch(values.value, () => saveValues());

function loadDropdownTemplates()
{
    dropdownTemplates.value = InstructTemplate.getAllTemplateNames();
}

onMounted(() => {
    loadDropdownTemplates();
});

defineExpose({
    showDialog: () => dialog.value.showDialog(),
});

// If copy is true, make a copy of the current template.  Otherwise, create a new template
// with nothing set.
async function createNewModelTemplate({ copy })
{
    let newTemplateName = await showTextEntryDialog({ title: "Template name:" });
    if(!newTemplateName)
        return;

    // Make sure this template doesn't already exist.
    let templates = await InstructTemplate.getAllTemplateNames();
    if(templates.includes(newTemplateName))
    {
        showMessage(`Template named "${newTemplateName}" already exists.`);
        return;
    }

    // Create the new template, copying the current one if needed.
    let newTemplate;
    if(copy)
        newTemplate = instructTemplate.value.copy(newTemplateName);
    else
        newTemplate = new InstructTemplate({
            name: newTemplateName,
        });

    newTemplate.save();

    instructTemplateName.value = newTemplateName;
    loadDropdownTemplates();
    loadValues();
    showMessage(`Created new template.`);
}

async function deleteModelTemplate()
{
    let deletingTemplate = instructTemplate.value;

    if(!await showConfirmation({ title: "Delete this template?", text: deletingTemplate.name }))
        return;

    // Select another template.
    instructTemplateName.value = InstructTemplate.getAllBuiltinTemplateName()[0];

    deletingTemplate.delete();
    loadDropdownTemplates();
    loadValues();
    showMessage(`Deleted template.`);
}

</script>
