<template>
    <v-list :lines="false" density="compact">
        <v-list-item prepend-icon="mdi-pencil" title="Edit"
            @click="() => props.interface.editMessage()"
        />
        <v-list-item prepend-icon="mdi-delete" v-if="!props.interface.isSystem()" title="Delete this message"
            @click="() => props.interface.deleteMessage()"
        />
        <v-list-item prepend-icon="mdi-delete" v-if="!props.interface.isSystem()" title="Delete from here"
            @click="() => props.interface.deleteFromHere()"
        />
        <v-list-item prepend-icon="mdi-refresh" v-if="!props.interface.isSystem()" title="Retry from here"
            @click="() => props.interface.retryFromHere()"
        />
        <v-list-item prepend-icon="mdi-account-arrow-up"  v-if="!props.interface.isSystem()" title="Insert above"
            @click="() => props.interface.insertMessage({direction: 'up'})"
        />
        <v-list-item prepend-icon="mdi-account-arrow-down" title="Insert below"
            @click="() => props.interface.insertMessage({direction: 'down'})"
        />

        <v-divider style="margin: 5px 0;" v-if="!props.interface.isSystem()" />

        <v-list-item
            v-for="(item, i) in characterNames" :key="i"
            v-if="!props.interface.isSystem()"
            :prepend-icon="item == 'user'? 'mdi-account-box':'mdi-account'"
            :title="item"
            :value="item"
            @click="(event) => toggleItem(item)"
            :active="selectedCharacters.indexOf(item) != -1"
        />
    </v-list>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useCharacterNames } from "@/misc/effects.js";
import InstructTemplate from "@/ai/instruct-template.js";
import * as Util from '@/misc/util';

const props = defineProps({
    editorView: { type: Boolean },
    interface: { type: Object },
});

const selectedRole = defineModel();
const selectedCharacters = ref([]);

const characterNames = ref([]);
const availableCharacterNames = useCharacterNames();

function parsedCharacterList()
{
    return Util.roleToDisplayNames(selectedRole.value);
}
selectedCharacters.value = parsedCharacterList();

function toggleItem(item)
{
    // For non-DreamGen, just set the selected role.
    if(!InstructTemplate.isDreamGen)
    {
        selectedCharacters.value = [item];
    }
    else
    {
        let idx = selectedCharacters.value.indexOf(item);
        if(idx != -1)
        {
            // Toggle this character off.
            selectedCharacters.value.splice(idx, 1);
        }
        else
        {
            // Toggle this character on.  "user" is mutually exclusive with characters.
            if(item == "user")
                selectedCharacters.value = ["user"];
            else
            {
                let userIdx = selectedCharacters.value.indexOf("user");
                if(userIdx != -1)
                    selectedCharacters.value.splice(userIdx, 1);

                selectedCharacters.value.push(item);
            }
        }
    }

    let role = Util.displayNamesToRole(selectedCharacters.value);
    props.interface.changeRole(role);
}

function updateAvailableCharacterNames()
{
    let names = Array.from(availableCharacterNames.value);

    // If this is DreamGen, the list includes available character names.  Add "user" to
    // the list.
    if(InstructTemplate.isDreamGen)
        names.push("user");

    // If any names are left selected that aren't in the list, add them to the list so
    // they can be deselected.  We'll refresh and remove them from the list if they are.
    for(let name of parsedCharacterList())
    {
        if(!names.includes(name))
            names.push(name);
    }

    characterNames.value = names;
}

watch(availableCharacterNames, () => updateAvailableCharacterNames());
updateAvailableCharacterNames();

</script>
