<template>
    <div style="display: flex; flex-wrap: wrap;">
        <v-card-text v-if="!isDreamGen">
            Story settings are only used with DreamGen models.
        </v-card-text>
        <v-card-text v-if="isDreamGen">
            Characters are global, and can be used in any chat.  Checkboxes enable or disable each character in this chat only.
        </v-card-text>
    </div>

    <div class=character-box>
        <div class=character-selector style="flex: .5; min-width: 300px;">
            <v-btn text prepend-icon="mdi-pencil" @click="createCharacter({copy: false})">New</v-btn>

            <v-select 
                v-model="selectedGroup" density=compact hide-details
                label="Group"
                style="width: 200px;"
                :items="groups"
            >
                <template v-slot:item="{ item, props }">
                    <v-list-item v-bind="props" />
                </template>
            </v-select>

            <v-list>
                <v-list-item
                    v-for="(character, index) in charactersInGroup(selectedGroup)"
                    :key="index"
                    :active="character.id == selectedCharacter?.id"
                    @click="selectCharacter(character)"
                >
                    <v-list-item-title v-text="character.name" />

                    <!-- Enabled checkbox: -->
                    <template v-slot:prepend>
                        <v-list-item-action start>
                            <v-switch
                                v-model="character.active" density=compact hide-details
                                @update:modelValue="activeChanged(character)"
                            />
                        </v-list-item-action>
                    </template>
                        
                    <template v-slot:append>
                        <v-list-item-action start>
                            <v-btn icon="mdi-folder" density=compact
                                @click="deleteCharacter(character)"
                            />
                            <v-btn icon="mdi-delete" density=compact
                                @click="deleteCharacter(character)"
                            />
                        </v-list-item-action>
                    </template>
                </v-list-item>
            </v-list>
        </div>
        <v-textarea
            label="Character description"
            class=text-entry
            ref=characterDescriptionEntry
            v-model="characterDescription"
            style="flex: 1; min-width: 300px;"
            @update:modelValue="characterDescriptionChanged()"
            :disabled="selectedCharacter == null"
            variant="outlined"
            auto-grow
            
        />
    </div>

    <v-card-text v-if="isDreamGen">
        Plot and style are only saved to the chat.
    </v-card-text>

    <div class=story-elements>
        <v-textarea
            label="Plot"
            class=text-entry
            v-model="plot"
            variant="outlined"
            auto-grow
            rows=1
        />

        <v-textarea
            label="Style"
            class=text-entry
            v-model="style"
            variant="outlined"
            auto-grow
            rows=1
        />
    </div>
</template>
    
<script setup>
import { ref, shallowRef, inject, watch, onMounted } from 'vue';
import characterDb, { Character } from "@/storytelling/characters.js";

import InstructTemplate from "@/ai/instruct-template.js";
import settings from "@/misc/settings.js";

const instructTemplate = new shallowRef(null);
const isDreamGen = InstructTemplate.dreamGenRef();
const characterDescription = ref("");
const characterDescriptionEntry = ref(null);
const selectedGroup = ref("");
const selectedCharacter = shallowRef(null);
const plot = ref("");
const style = ref("");
const groups = ref([]);
const characters = ref([]);
const showTextEntryDialog = inject('showTextEntryDialog');
const showConfirmation = inject('showConfirmationDialog');

const props = defineProps({
    currentTab: { type: String },
});

const activeChat = inject("activeChat");

instructTemplate.value = InstructTemplate.getTemplateByName(settings.values.instructTemplate);

function charactersInGroup(group)
{
    if(group == null)
        return characters.value;
    return characters.value.filter(c => c.group == group);
}

function load()
{
    plot.value = activeChat.value.plot;
    style.value = activeChat.value.style;
    let activeCharacters = activeChat.value.characters;
    console.log("activeCharacters", activeCharacters);

    let characterIds = characterDb.getAllIds();

    let characterInfo = [];
    let groupNames = new Set();
    groupNames.add({
        title: "All",
        value: null,
    });
    for(let id of characterIds)
    {
        let character = characterDb.getById(id);
        groupNames.add({
            title: character.group,
            value: character.group,
        });

        characterInfo.push({
            id: character.id,
            name: character.name,
            group: character.group,
            active: activeCharacters.indexOf(character.id) != -1,
        });
    }
    characters.value = characterInfo;
    groups.value = Array.from(groupNames);
    console.log("groups:", groups.value);
}

// Update the active character list on the current chat when the checkbox is changed.
function activeChanged(characterInfo)
{
    let activeCharacters = activeChat.value.characters;
    let active = characterInfo.active;
    if(active && activeCharacters.indexOf(characterInfo.id) == -1)
        activeCharacters.push(characterInfo.id);
    else if(!active && activeCharacters.indexOf(characterInfo.id) != -1)
        activeCharacters.splice(activeCharacters.indexOf(characterInfo.id), 1);
    activeChat.value.save();
}

function characterDescriptionChanged()
{
    let character = characterDb.getById(selectedCharacter.value.id);
    if(!character)
    {
        console.log("Selected character is missing");
        return;
    }
    character.description = characterDescription.value;
    character.save();
}

watch(plot, () => { activeChat.value.plot = plot.value; activeChat.value.save(); });
watch(style, () => { activeChat.value.style = style.value; activeChat.value.save(); });

watch(selectedCharacter, () => {
    let character = characterDb.getById(selectedCharacter.value?.id);
    characterDescription.value = character?.description ?? "";
});

onMounted(() => {
    load();
    selectedGroup.value = null;
});

async function createCharacter()
{
    let name = await(showTextEntryDialog({title: "Character name:"}));
    if(!name)
        return;

    let character = new Character({ name, group: "test" });
    await character.save();
    load();

    selectCharacter(character);
}

function selectCharacter(character)
{
    selectedCharacter.value = character;
    if(character != null)
        setTimeout(() => characterDescriptionEntry.value.focus(), 0);
}

async function deleteCharacter(character)
{
    if(!await showConfirmation({ text: `Delete ${character.name}?  This can't be undone.` }))
        return;

    characterDb.delete(character.id);
    if(selectedCharacter.value?.id == character.id)
        selectedCharacter.value = null;

    load();
}
</script>

<style scoped>

.text-entry {
    max-width: 600px
}

.character-box, .story-elements
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 1em;
}
.story-elements {
}
</style>