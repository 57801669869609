import { onUnmounted, ref } from 'vue';
import InstructTemplate from "@/ai/instruct-template.js";

// Based on:
//
// https://stackoverflow.com/a/41580048/136829
export function createPartialMatchRegex(re)
{
    let source = re.source;
    let i = 0;
    
    let result = "";

    function appendRaw(nbChars)
    {
        result += source.substr(i, nbChars);
        i += nbChars;
    };
    
    function appendOptional(nbChars)
    {
        result += "(?:" + source.substr(i, nbChars) + "|$)";
        i += nbChars;
    };

    while(i < source.length)
    {
        switch(source[i])
        {
        case "\\":
            switch(source[i + 1])
            {
            case "c": appendOptional(3); break; // \cX
            case "x": appendOptional(4); break; // \xXX
                
            case "u":
                console.log(re.unicode);
                if (re.unicode) {
                    if (source[i + 2] === "{")
                        appendOptional(source.indexOf("}", i) - i + 1); // \u{XXXX}
                    else
                        appendOptional(6); // \uXXXX
                } else {
                    appendOptional(2); // \u
                }
                break;

            case "p":
            case "P":
                if(re.unicode)
                    appendOptional(source.indexOf("}", i) - i + 1); // \p{XXXX}
                else
                    appendOptional(2); // \p
                break;

            case "k":
                appendOptional(source.indexOf(">", i) - i + 1); // <k...>
                break;
                
            default:
                appendOptional(2);
                break;
            }
            break;
            
        case "[":
        {
            // [abcdefg]
            let tmp = /\[(?:\\.|.)*?\]/g;
            tmp.lastIndex = i;
            tmp = tmp.exec(source);
            appendOptional(tmp[0].length);
            break;
        }   
        case "|":
        case "^":
        case "$":
        case "*":
        case "+":
        case "?":
            appendRaw(1);
            break;
            
        case "{":
        {
            // {10,20}
            let tmp = /\{\d+,?\d*\}/g;
            tmp.lastIndex = i;
            tmp = tmp.exec(source);
            if(tmp)
                appendRaw(tmp[0].length);
            else
                appendOptional(1);
            break;
        }   
        case "(":
            if(source[i + 1] != "?")
            {
                // (abcd)
                appendRaw(1);
                result += process() + "|$)";
                break;
            }

            switch (source[i + 2])
            {
            case ":":
                // (?:abcd)
                result += "(?:";
                i += 3;
                result += process() + "|$)";
                break;
                
            case "=":
                // (?=abcd)
                result += "(?=";
                i += 3;
                result += process() + ")";
                break;
                
            case "!":
            {
                // (?!abcd)
                let tmp = i;
                i += 3;
                process();
                result += source.substr(tmp, i - tmp);
                break;
            }
            case "<":
                switch (source[i + 3])
                {
                    // (<=
                    case "=":
                    case "!":
                    {
                        let tmp = i;
                        i += 4;
                        process();
                        result += source.substr(tmp, i - tmp);
                        break;
                    }
                    default:
                        appendRaw(source.indexOf(">", i) - i + 1);
                        result += process() + "|$)";
                        break;        
                }
                break;
            }
            break;
            
        case ")":
            ++i;
            return result;
            
        default:
            appendOptional(1);
            break;
        }
    }
    
    return new RegExp(result, re.flags);
};

export class StreamLineReader
{
    constructor(reader, { signal })
    {
        this.reader = reader;
        this.buffer = new Uint8Array();
        this.done = false;
        this.signal = signal;
    }

    async nextLine()
    {
        // Why do streams not support AbortSignal like everything else?
        let cancel = () => this.reader.cancel();
        this.signal.addEventListener("abort", cancel);

        try {
            while(true)
            {
                const newline = '\n'.charCodeAt(0);

                // See if we've reached a newline.
                let newlineIndex = this.buffer.indexOf(newline);
                if(newlineIndex != -1)
                {
                    let line = this.buffer.slice(0, newlineIndex);
                    this.buffer = this.buffer.slice(newlineIndex + 1);
                    if(line[line.byteLength-1] == "\r")
                        line = line.slice(0, -1);
                    return line;
                }

                // Read the next packet.
                const { done, value } = await this.reader.read();

                if(done)
                {
                    this.done = true;

                    if(this.buffer.length == 0)
                        return null;
                    let remaining = this.buffer;
                    this.buffer = '';
                    if(remaining[remaining.length-1] == "\r")
                        remaining = remaining.slice(0, -1);
                    return remaining;
                }

                // This copies the whole existing buffer, but in practice lines usually come in
                // big chunks, not byte by byte, so this doesn't happen that often.
                let newBuffer = new Uint8Array(this.buffer.length + value.length);
                newBuffer.set(this.buffer, 0);
                newBuffer.set(value, this.buffer.length);
                this.buffer = newBuffer;
            }
        } finally {
            this.signal.removeEventListener("abort", cancel);
        }
    }
}

export async function *eventStreamReader(reader, { signal })
{
    let lineReader = new StreamLineReader(reader, { signal });
    let data = [];
    let type = "message";
    let id = null;
    let decoder = new TextDecoder("utf-8");
    while(1)
    {
        let event = await lineReader.nextLine();
        if(event == null)
            break;

        event = decoder.decode(event);

        let keyIdx = event.indexOf(":");
        if(keyIdx == -1)
        {
            yield { id, type, data };
            data = [];
            type = "message";
            id = null;
            continue;
        }

        let key = event.slice(0, keyIdx);
        let value = event.slice(keyIdx + 1).trim();
        if(key == "")
            continue; // comment

        if(key = "data")
        {
            value = JSON.parse(value);
            data.push(value);
            continue;
        }

        if(key == "event")
        {
            type = value;
            continue;
        }

        if(key == "id")
        {
            id = value;
            continue;
        }
    }

}

export function useAbort()
{
    let abortController = ref(new AbortController());

    onUnmounted(() => {
        abortController.value.abort();
    });

    return abortController.value.signal;
}

// Given a message role, return a list of display names.
//
// For regular chats, 
export function roleToDisplayNames(roles)
{
    // For regular models, roles is just the role name.
    if(!InstructTemplate.isDreamGen)
        return [roles];

    // If the role is "text", no characters are selected.
    if(roles == "text")
        return [];

    // Parse DreamGen character name lists.
    if(roles.startsWith("text names= "))
    {
        let names = roles.slice(12).split("; ");
        return names;
    }

    return [roles];
}

// Convert a list of display names back to a role.
export function displayNamesToRole(displayNames)
{
    let handleAsDreamGen = InstructTemplate.isDreamGen;

    // If we have more than one display name, always handle roles as DreamGen.
    if(displayNames.length > 1)
        handleAsDreamGen = true;

    if(!handleAsDreamGen)
        return displayNames[0];

    // Return "user" as-is.
    if(displayNames.length == 1 && displayNames[0] == "user")
        return "user";

    // Encode the role list in DreamGen format: "text names= name1; name2; name3".
    // "user" is standalone, so leave it as a regular role.
    let role = "text";
    if(displayNames.length > 0)
        role = `text names= ${displayNames.join("; ")}`;
    return role;
}

// This is used when we're in DreamGen mode and the role is "text?".  In this mode
// we've sent an instruct header "<|im_start|>text" to the model, and we're allowing
// the model to fill in the rest.  Data up until the first newline is the rest of the
// role string, eg. "text names= Alice; Bob; Charlie".  Read until we see that and fill
// the message's role with it, then continue as normal.
export async function *handleDreamGenStream(stream)
{
    let buffer = "";
    let gotRole = false;
    for await(let { token, topTokens } of stream)
    {
        if(gotRole)
        {
            yield { token, topTokens };
            continue;
        }

        buffer += token;
        let idx = buffer.indexOf("\n");
        if(idx == -1)
            continue;

        // If the model didn't output anything before the end of the line, this is
        // just a "text" role with no names.  Otherwise, it should be " names Name1; Name2".
        let role = buffer.slice(0, idx);
        role = "text" + role;
        console.log(`Got role: ${role}`);

        activeChat.value.latestMessage.role = role;

        buffer = buffer.slice(idx+1);
        gotRole = true;
        yield { token: buffer };

        buffer = "";
    }

    // If anything's in the buffer, we never saw a newline.
    console.log(`DreamGen prompt didn't return a role`);
    yield { token: buffer };
}

export async function sleep(ms, { signal }={})
{
    signal ??= new AbortController().signal;
    return new Promise((accept) => {
        let timeout = null;
        let abort = () => {
            clearTimeout(timeout);
            accept();
        };

        signal.addEventListener("abort", abort, { once: true });

        timeout = setTimeout(() => {
            signal.removeEventListener("abort", abort, { once: true });
            accept();
        }, ms);
    });
}

// A buffering wrapper for an EventStream reader.
export async function *readStreamInBatches(stream, { signal })
{
    let buffer = [];
    let done = false;
    async function readLoop()
    {
        for await(let data of stream)
            buffer.push(data);
        done = true;
    }

    let loopPromise = readLoop();

    // Periodically check and return packets.
    while(!done && !signal.abort)
    {
        // The actual sleep time doesn't need to be very big, since the main
        // goal is to allow packets to buffer if the update during the yield
        // takes a long time.
        await sleep(10, { signal });

        if(buffer.length > 0)
        {
            let batch = buffer;
            buffer = [];
            yield batch;
        }
    }

    // If we're aborted, the stream should be aborted too.  Wait for the
    // read loop to exit.
    await loopPromise;
}

// Store the selection.
//
// This is only guaranteed to work if the document matches up to the selection, which
// happens when we're appending during generation.  Otherwise, we make a best effort.
export function serializeSelection()
{
    let selection = window.getSelection();
    if(selection.rangeCount === 0)
    {
        return {
            restore: () => {},
        };
    }

    let range = selection.getRangeAt(0);
    let startContainerPath = [];
    for(let node = range.startContainer; node !== document.body; node = node.parentNode)
        startContainerPath.unshift(Array.prototype.indexOf.call(node.parentNode.childNodes, node));

    let endContainerPath = [];
    for(let node = range.endContainer; node !== document.body; node = node.parentNode)
        endContainerPath.unshift(Array.prototype.indexOf.call(node.parentNode.childNodes, node));

    let data = {
        start: generateSelector(range.startContainer),
        end: generateSelector(range.endContainer),
        startOffset: range.startOffset,
        endOffset: range.endOffset
    };

    // console.log("Saved:", data.startOffset, data.start);
    // console.log("End:", data.endOffset, data.end);

    return {
        restore: () => {
            deserializeSelection(data);
        },
    };
}

function generateSelector(node)
{
    // Store a selector to get down to the text node.  Store the text node index
    // separately, since they can't be selected with selectors.
    let selector = "";
    let childIndex = null;
    while(node !== document.body)
    {
        if(node.nodeType === Node.ELEMENT_NODE)
        {
            let index = getNthChildIndex(node);
            let segment = `:nth-child(${index})`;
            if(node.id)
            {
                segment = '#' + node.id;
            }

            selector = segment + ' > ' + selector;
        } else if (node.nodeType === Node.TEXT_NODE) {
            childIndex = Array.prototype.indexOf.call(node.parentNode.childNodes, node);
        }
    
        node = node.parentNode;
    }
    
    return {
        selector: selector.slice(0, -3),  // Remove the trailing ' > '
        childIndex: childIndex
    };
}

// Return the :nth-selector index for a given node.
function getNthChildIndex(node)
{
    let count = 1;
    let child = node;
    // Count only previous siblings that are element nodes
    while(child.previousElementSibling)
    {
        child = child.previousElementSibling;
        count++;
    }
    return count;
}

function deserializeSelection(serializedSelection)
{
    if(!serializedSelection)
        return;

    try {
        let { start, end, startOffset, endOffset } = serializedSelection;
        let { selector: startSelector, childIndex: startChildIndex } = start;
        let { selector: endSelector, childIndex: endChildIndex } = end;

        let startNode = document.querySelector(startSelector);
        let endNode = document.querySelector(endSelector);

        if(startNode && startChildIndex !== null)
            startNode = startNode.childNodes[startChildIndex];
        if(endNode && endChildIndex !== null)
            endNode = endNode.childNodes[endChildIndex];

        if(startNode && endNode)
        {
            let range = new Range();
            range.setStart(startNode, startOffset);
            range.setEnd(endNode, endOffset);

            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        }
    } catch(e) {
        // For some reason, childNodes is different from every other array-like thing in JS and
        // throws on out of bounds.
        if(e instanceof DOMException)
            return;
        throw e;
    }
}

// If the selection is within bottomPadding of the bottom of the viewport, scroll
// scrollContainer up.
export function scrollCursorIntoView(scrollContainer, bottomPadding)
{
    let selection = window.getSelection();
    if(selection.focusNode == null)
        return;

    let range = document.createRange();
    range.setStart(selection.focusNode, selection.focusOffset);
    range.setEnd(selection.focusNode, selection.focusOffset);

    let rect = range.getBoundingClientRect();

    // Work around an API design bug: if there's no text in the node the cursor is on,
    // range.getBoundingClientRect breaks and returns empty, which doesn't make sense.
    // If this happens, look at the size of the contenteditable around the focusNode,
    // if any.
    if(rect.height == 0)
    {
        let node = selection.focusNode;
        if(node.nodeType === Node.TEXT_NODE)
            node = node.parentNode;
        let editableNode = node.closest("[contenteditable]");
        if(editableNode == null)
            return;

        rect = editableNode.getBoundingClientRect();
    }

    let viewportHeight = window.innerHeight;
    if(rect.bottom > viewportHeight - bottomPadding)
        scrollContainer.scrollTop += rect.bottom - (viewportHeight - bottomPadding);
}

export function *traverseDom(node)
{
    yield { enter: true, node };

    let child = node.firstChild;
    while(child)
    {
        yield* traverseDom(child);
        child = child.nextSibling;
    }

    yield { enter: false, node };
}

export function logProbsToTopTokens(logProbs)
{
    let topTokens = {};
    // Convert from logprobs to normalized probabilities.
    for(let [key, value] of Object.entries(logProbs))
        topTokens[key] = Math.exp(value);

    let sum = 0;
    for(let value of Object.values(topTokens))
        sum += value;

    for(let key of Object.keys(topTokens))
        topTokens[key] /= sum;

    // Round probabilities to have two digits of precision, so they serialize to JSON
    // more cleanly.
    for(let [key, value] of Object.entries(topTokens))
        topTokens[key] = Math.round(value * 10000) / 10000;

    return topTokens;
}

// Parse "quotes" into <q>quotes</q>.
//
// Extending remark for simple things is a nightmare, so do this as a postprocessing
// step.
export function wrapQuotesWithQ(rootNode)
{
    let openQuote = null;
    function checkNode(node)
    {
        // Don't apply this to code, and don't recurse into strings we've already wrapped.
        if(node.nodeName == "CODE" || node.nodeName == "Q")
            return "skipTree";

        if(node.nodeName != "#text")
            return "finished";

        let text = node.nodeValue;
        let textContainer = node.parentNode;
        let parent = textContainer?.parentNode;

        // Process each text node for quotes
        for(let position = 0; position < text.length; position++)
        {
            // We only look for regular double quotes.  This is what LLMs usually output.
            // Handling single quotes would be more complicated since we'd have to figure out
            // contractions.
            if(text[position] != '"')
                continue;

            // If this quote doesn't match our open quote, start a new one, discarding the
            // previous one if any.
            if(openQuote == null || openQuote.parent != parent)
            {
                // console.log(`Found open quote at ${position}:`, node.nodeValue.substr(position));
                openQuote = { node, parent, position };
                continue;
            }

            // Found a matching close quote.
            // console.log(`Found close quote at ${position}`, node.nodeValue.substr(position));

            // Create a range surrounding the quotes.
            let range = document.createRange();
            range.setStart(openQuote.node, openQuote.position);
            range.setEnd(node, position+1);

            // surroundContents is broken: it throws exceptions in normal cases, so just do
            // this manually.
            let q = document.createElement("q");
            q.appendChild(range.extractContents());
            range.insertNode(q);

            // Replace ASCII double quotes with Unicode quotes.
            q.firstChild.textContent = q.firstChild.textContent.replace(/^"/, "“");
            q.lastChild.textContent = q.lastChild.textContent.replace(/"$/, "”");
            
            openQuote = null;

            // Try again to see if there are any more quotes on this node.
            return "repeat";
        }

        return "finished";
    }

    // If we have multiple quotes on the same node, we'll replace one per pass.
    let iterator = document.createTreeWalker(rootNode, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT);
    let node = iterator.nextNode();
    while(node != null)
    {
        let result = checkNode(node);
        if(result == "skipTree")
        {
            node = iterator.nextSibling();
            continue;
        }

        if(result == "repeat")
            continue;

        console.assert(result == "finished");
        node = iterator.nextNode();
    }
}
