<template>
    <ChatDisplay v-if="props.chatId != null" :chatId="props.chatId" />
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useAbort } from '@/misc/util';
import ChatDisplay from './Chat.vue';
import { Chat } from '@/misc/chat'
import { chatDb } from '@/misc/chat'

const router = useRouter();
const signal = useAbort();

const props = defineProps({
    chatId: { type: Number },
});

async function getFirstChat()
{
    let chats = chatDb.getAll();
    let chat = chats[0];
    if(chat != null)
        return chat;

    console.log("Creating first chat");
    chat = Chat.defaultChat();

    await chat.save(chat);
    return chat;
}

// Return the ID of the next or previous chat around ours in the list if there is one.
// Otherwise, return null.
async function findNearbyChat(chatId)
{
    let availableChats = chatDb.getAll();
    let ourIndex = availableChats.findIndex((c) => c.id === chatId);
    if(ourIndex < availableChats.length - 1)
        ourIndex++;
    else
        ourIndex--;
    return availableChats[ourIndex]?.id;
}

// Make sure the chat we're viewing exists.  If it doesn't, do something about it.
let fallbackChatId = null;
async function checkCurrentChat()
{
    let chatId = props.chatId;

    if(chatId != null)
    {
        // Make sure the chat exists.  If it doesn't, clear chatId so we'll pick
        // a different one below, as if no chat had been specified.
        let chat = chatDb.getById(chatId);
        if(chat == null)
        {
            // console.log("Chat not found, navigating to default chat");
            chatId = null;
        }
    }

    if(chatId != null)
    {
        // We have a valid chat.  Remember another nearby valid chat too.  We'll switch to
        // this one if the chat we're on disappears.
        fallbackChatId = await findNearbyChat(chatId);
        return;
    }

    // We're either on a chat ID that no longer exists, or no chat was specified.  If we
    // have a fallbackChatId, switch to that one, so deleting the selected chat goes to
    // a reasonable fallback.  Otherwise, ask getFirstChat.
    let id;
    if(fallbackChatId != null)
    {
        id = fallbackChatId;
        fallbackChatId = null;
    }
    else
    {
        let chat = await getFirstChat();
        id = chat.id;
    }
    router.replace({ name: 'chat', params: { id } });
}

// If the chat we're on is deleted, re-check.
chatDb.addEventListener("entry-list-changed", () => {
    checkCurrentChat();
}, { signal });

onMounted(async() => checkCurrentChat());
</script>

<style scoped>
</style>
