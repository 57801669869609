// Most instruct models have three roles: system, the user and the assistant.
// Some instruct formats are consistent and just have a header with the role,
// like ChatML.  Others use different special tokens for each role, or inconsistent
// ad hoc messages.
//
// Additionally, some models like DreamGen allow arbitrary roles, not just "user"
// and "assistant".  If a sequence contains {{role}}, it'll be replaced with the
// actual role name.
import settings from "@/misc/settings.js";
import { reactive, shallowReactive, triggerRef, watch } from 'vue';
import { useAbort } from '@/misc/util';

export default class InstructTemplate
{
    static _changedEventTarget = new EventTarget();

    // A shortcut to check if DreamGen is enabled on the active template, since this
    // is done often.
    static get isDreamGen()
    {
        let instructTemplate = InstructTemplate.getActiveTemplate();
        return instructTemplate.dreamGen;
    }

    // Return a ref which is true when DreamGen is active.
    static dreamGenRef()
    {
        const template = reactive({ value: InstructTemplate.isDreamGen });
        watch(this.activeTemplateRef(), () => template.value = InstructTemplate.isDreamGen);
        return template;
    }

    constructor({
        name,
        readonly=false,

        systemPrefix, systemSuffix,
        userPrefix, userSuffix,
        assistantPrefix, assistantSuffix,
        stopSequence,
        dreamGen=false,
    }={})
    {
        this.name = name;
        this.readonly = readonly;

        this.systemPrefix = systemPrefix;
        this.systemSuffix = systemSuffix;
        this.userPrefix = userPrefix;
        this.userSuffix = userSuffix;
        this.assistantPrefix = assistantPrefix;
        this.assistantSuffix = assistantSuffix;
        this.stopSequence = stopSequence;
        this.dreamGen = dreamGen;
    }

    toJSON()
    {
        return {
            systemPrefix: this.systemPrefix,
            systemSuffix: this.systemSuffix,
            userPrefix: this.userPrefix,
            userSuffix: this.userSuffix,
            assistantPrefix: this.assistantPrefix,
            assistantSuffix: this.assistantSuffix,
            stopSequence: this.stopSequence,
            dreamGen: this.dreamGen,
        };
    }

    copy(newName)
    {
        let newTemplate = InstructTemplate.fromJSON(this.toJSON());
        console.log(this.toJSON());
        newTemplate.name = newName;
        return newTemplate;
    }

    static fromJSON(json)
    {
        return new InstructTemplate(json);
    }

    static getTemplateByName(name)
    {
        let template = localStorage[`template/${name}`];
        let readonly = template == null;
        if(template)
            template = JSON.parse(template);
        else
            template = InstructTemplate._formats[name];

        return new InstructTemplate({
            ...template,
            name,
            readonly,
        });
    }

    save()
    {
        if(this.readonly)
            throw new Error("Cannot save a read-only template");

        localStorage[`template/${this.name}`] = JSON.stringify(this.toJSON());

        console.log("template saved");
        let e = new Event("saved");
        e.templateName = this.name;
        InstructTemplate._changedEventTarget.dispatchEvent(e);
    }

    delete()
    {
        if(this.readonly)
            throw new Error("Cannot delete a read-only template");

        delete localStorage[`template/${this.name}`];
    }

    static getActiveTemplate()
    {
        let name = settings.values.instructTemplate;
        return this.getTemplateByName(name);
    }

    static getAllTemplateNames()
    {
        return [
            ...this.getAllSavedTemplateNames(),
            ...this.getAllBuiltinTemplateName(),
        ];
    }

    static getAllSavedTemplateNames()
    {
        let keys = Object.keys(localStorage);
        let layerKeys = keys.filter((key) => key.startsWith("template/"));
        return layerKeys.map((key) => key.slice("template/".length));
    }

    static getAllBuiltinTemplateName()
    {
        return Object.keys(this._formats);
    }

    static getAllTemplates()
    {
        let names = this.getAllTemplateNames();
        let results = { };
        for(let name of names)
            results[name] = this.getTemplateByName(name);
        return results;
    }

    // Return a reactive object that updates when the active template or any of its data changes.
    static activeTemplateRef()
    {
        const signal = useAbort();

        const template = shallowReactive({ value: InstructTemplate.getActiveTemplate() });

        // Refresh if the instructTemplate setting changes.
        settings.watch("instructTemplate", () => template.value = InstructTemplate.getActiveTemplate());

        // Trigger the reference if any InstructTemplate is saved.
        InstructTemplate._changedEventTarget.addEventListener("saved", (e) => {
            template.value = InstructTemplate.getActiveTemplate();

            // Force a refresh for the shallow ref.
            triggerRef(template);
        }, { signal });

        return template;
    }

    static _formats = {
        "Llama 3": {
            systemPrefix: "<|start_header_id|>system<|end_header_id|>\n\n",
            systemSuffix: "<|eot_id|>",
            userPrefix: "<|start_header_id|>user<|end_header_id|>\n\n",
            userSuffix: "<|eot_id|>",
            assistantPrefix: "<|start_header_id|>assistant<|end_header_id|>\n\n",
            assistantSuffix: "<|eot_id|>",
            stopSequence: "<|eot_id|>",
        },
        "DreamGen": {
            systemPrefix: "<|im_start|>system\n",
            systemSuffix: "<|im_end|>\n",
            userPrefix: "<|im_start|>user\n",
            userSuffix: "<|im_end|>\n",
            assistantPrefix: "<|im_start|>",
            assistantSuffix: "<|im_end|>\n",
            stopSequence: "<|im_start|>",
            dreamGen: true,
        },
    };
}
