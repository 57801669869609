import Dexie from "dexie";
import ObjectDb from "@/misc/object-db.js";


class CharacterDatabase extends ObjectDb
{
    get entryClass() { return Character; }

    getDb()
    {
        let db = new Dexie("characters");
        db.version(1).stores({
            characters: "++id, name, description",
        });
        return db;
    }

    async getTable()
    {
        let db = await this.open();
        return db.characters;
    }
}

let singleton = new CharacterDatabase();
export default singleton;

export class Character
{
    constructor({id=undefined, name, group, description=""})
    {
        this.id = id;
        this.name = name;
        this.group = group;
        this.description = description;
    }

    toJSON()
    {
        let { id, name, group, description } = this;
        return { id, name, group, description }
    }

    fromJSON(json)
    {
        return new Character(json);
    }

    async save()
    {
        await singleton.save(this);
    }
}
