<script setup>
import { ref, onMounted, onUnmounted, inject, watch, watchEffect, watchSyncEffect } from 'vue';
import { useTitle } from '@vueuse/core'
import { useEventListener, useScroll, useTextSelection, onKeyStroke as onKeystroke  } from '@vueuse/core'

import * as foo from "@/misc/testing.js";
console.log(foo);

import connection from "@/ai/connection.js";
import * as Util from '@/misc/util';
import settings from "@/misc/settings.js";
import InputBar from "./InputBar.vue";
import { chatDb } from '@/misc/chat';
import MessageDropdown from './MessageDropdown.vue';
import TokenDropdown from './TokenDropdown.vue';
import InstructTemplate from "@/ai/instruct-template.js";

import { messagesToDom, messageToDom } from './message-helpers';

const props = defineProps({
    chatId: { type: Number },
});

const signal = Util.useAbort();
const showMessage = inject("showMessage");

const dialogue = ref(null);
const dialogueContainer = ref(null);

const canUndo = ref(false);
const canRedo = ref(false);

// Message dropdown properties
const showingMessageDropdown = ref(false);
const showingMessageDropdownRole = ref([]);
const showingMessageDropdownAtElement = ref(null);
const characterDropdownInterface = ref(null);

// Token list properties (there's got to be a better way to do this)
const showingTokenListDropdown = ref(false);
const showingTokenListDropdownAtElement = ref(null);
const showingTokenListDropdownTokens = ref([]);
const showingTokenListDropdownSelectedToken = ref("");
const showingTokenListDropdownInterface = ref(null);

// The loaded chat.  This lives on the app so it can be accessed from anywhere.
const activeChat = inject("activeChat");
const title = useTitle();

// Style settings:
const chatFontSize = settings.use("chatFontSize");
const chatLineSpacing = settings.use("chatLineSpacing");
const chatParagraphSpacing = settings.use("chatParagraphSpacing");
const chatMaxWidth = settings.use("chatMaxWidth");

let activeGenerationBackend = null;
let abortGeneration = null;
const isGenerating = ref(false);
const isDreamGen = InstructTemplate.dreamGenRef();

// If true, we're generating and we detected the user trying to scroll or change the
// selection, so stop trying to scroll to the bottom until the end of the generation.
let disableGenerationAutoScroll = false;
let scrollerLastTopPos = 0;

function findDOMNodeForMessage(message)
{
    // Find the message in the chat.
    let messageIdx = activeChat.value.messages.indexOf(message);
    if(messageIdx == -1)
        throw new Error(`Couldn't find message ${message} in chat`);

    return dialogueContainer.value.children[messageIdx];
}

// Return the message for the given message node.
//
// Returns null if node is the system message.
function findMessageForDOMNode(node)
{
    let messageIdx = Array.from(node.parentNode.children).indexOf(node);
    return activeChat.value.messages[messageIdx];
}

// Update a message in the editor that was changed outside of the editor.
function updateChangedMessage(message)
{
    let oldMessageNode = findDOMNodeForMessage(message);
    if(oldMessageNode == null)
    {
        console.warn("Changed non-existant message", message);
        return;
    }

    // Remember the original selection.
    let savedSelection = Util.serializeSelection();
    let scrollTop = dialogue.value.scrollTop;

    // Create the new editor message and replace it in the document.
    let newMessageNode = messageToDom(message);
    oldMessageNode.parentNode.replaceChild(newMessageNode, oldMessageNode);

    // Restore the user's selection.
    if(!isGenerating.value)
        savedSelection.restore();

    // Restore the scroll position.  it may have been adjusted in unwanted ways
    // when we changed the node.  If we're in generation and we want to scroll
    // down, scrollMessageNodeIntoView will be called.
    dialogue.value.scrollTop = scrollTop;

    afterChanges();
}

// This is called when we need to repopulate the whole list, such as when messages
// are added or removed.
function messagesChanged()
{
    let savedSelection = Util.serializeSelection();

    while(dialogueContainer.value.firstChild)
        dialogueContainer.value.firstChild.remove();

    messagesToDom(activeChat.value.messages, dialogueContainer.value);

    savedSelection.restore();

    // Close all dropdowns if a major change happened.
    closeAllDropdowns();
    
    afterChanges();
}

function afterChanges()
{
    // If the last message if a user message, it's always editable.  This makes
    // sure it's editable if a message after it is deleted, or other changes cause
    // the last message to change.
    let latestMessage = activeChat.value.latestMessage;
    if(latestMessage.role == "user")
        latestMessage.editing = true;

    // Close the token list dropdown if something changes.
    showingTokenListDropdown.value = false;
}

// If the system prompt changes externally (such as in the settings dialog), update
// the chat's system prompt.
settings.watch("systemPrompt", () => {
    let message = activeChat.value.messages[0];
    updateChangedMessage(message);
});

function scrollMessageNodeIntoView(messageNode)
{
    // Scroll the bottom of messageNode to the bottom.  If we're already below it due
    // to the padding on the scroller, don't scroll up.
    let scrollThreshold = 60;
    let bottomOfMessage = messageNode.getBoundingClientRect().bottom;
    let bottomOfScroller = dialogue.value.getBoundingClientRect().bottom;
    let scrollBy = bottomOfMessage - bottomOfScroller + scrollThreshold;
    if(scrollBy > 0)
        dialogue.value.scrollTop = dialogue.value.scrollTop + scrollBy;

    // Make sure this scroll doesn't change the disableGenerationAutoScroll state.
    ignoreScrolling();
}

// Retry generation by deleting starting at fromMessage.
async function retryGeneration({ fromMessage=null }={})
{
    // If no message to retry from was given, figure it out.
    function getMessageToRetryFrom()
    {
        if(activeChat.value.messages.length < 1)
            return null;

        // Look back up to two messages.
        for(let messagesAgo = 1; messagesAgo <= 2; ++messagesAgo)
        {
            let messageIdx = activeChat.value.messages.length - messagesAgo;
            let lastMessage = activeChat.value.messages[messageIdx];

            // If this is an assistant message, retry from here.
            if(lastMessage.isAssistantMessage)
                return lastMessage;

            // If this is an empty user message, ignore it and look at the previous message.
            // If it's a user message with content, stop and just generate normally.
            console.assert(lastMessage.role == "user", lastMessage);
            if(lastMessage.content.length > 0)
                return null;
        }

        return null;
    }

    if(fromMessage == null)
        fromMessage = getMessageToRetryFrom();

    if(fromMessage != null)
        activeChat.value.removeFromMessage(fromMessage);

    await beginGeneration("");
}

async function beginGeneration()
{
    if(activeGenerationBackend)
    {
        console.log("Already generating");
        return;
    }

    isGenerating.value = true;
    activeChat.value.suspendUndo = true;
    try {
        await beginGenerationInner();
    } finally {
        isGenerating.value = false;
        activeChat.value.suspendUndo = false;
    }
}

async function beginGenerationInner()
{
    // Commit any unsaved changes to editable messages.
    saveEditing();

    // If the latest message from the user is empty and the one before it is ongoing,
    // delete the empty message to allow the ongoing message to resume.  If the previous
    // message isn't ongoing, we'll start a new response, which is important for DreamGen.
    if(activeChat.value.latestMessage?.content == "" && activeChat.value.messages.length > 1)
    {
        let previousMessage = activeChat.value.messages[activeChat.value.messages.length-2];
        if(previousMessage.ongoing)
        {
            dialogueContainer.value.lastChild.remove();
            activeChat.value.removeLastMessage();
        }
    }

    // If we're starting a new message and not continuing an existing one, create it
    // with the desired role.  For regular models, use the "assistant" role.  For
    // DreamGen, use the selected character role.
    let latestMessage = activeChat.value.latestMessage;
    let readRoleFromResponse = false;
    if(!latestMessage.ongoing)
    {
        // if the last generation is complete and we're starting a new turn, append
        let roles = settings.values.activeRoles;
        let responseRole = "assistant";
        if(InstructTemplate.isDreamGen)
        {
            if(roles.indexOf('*') != -1)
            {
                // Set the role to the "text?" placeholder to remember that we need a
                // name, and enable handleDreamGenStream to read it.
                responseRole = 'text?';
                readRoleFromResponse = true;
            }
            else if(roles.length == 0)
                responseRole = `text`;
            else
                responseRole = `text names= ${roles.join('; ')}`;
        }

        console.log(`Creating empty assistant response, role: ${responseRole}`);
        activeChat.value.createNewMessage({ role: responseRole, ongoing: true });
    }

    activeGenerationBackend = connection.getBackend();

    // Get the prompt so far.
    let { prompt, availableContext, error } = await activeChat.value.getPrompt(activeGenerationBackend);
    if(error)
    {
        showMessage(error);
        return;
    }

    // console.log(prompt);

    let abort = new AbortController();
    let { signal } = abort;
    abortGeneration = () => abort.abort();

    // Reset autoscroll when we start generating, so we always start scrolling at first.
    disableGenerationAutoScroll = false;

    let generation = activeGenerationBackend.generate({
        prompt,
        availableContext,
        signal,
    });

    // If this is a DreamGen instruct and we're in "text?" mode, pipe the results through
    // handleDreamGenStream to intercept the role that the model adds, if any.
    if(readRoleFromResponse)
        generation = Util.handleDreamGenStream(generation);

    // This buffers input a little, so if we're receiving a lot of tokens at once
    // (such as from a slow network connection), we'll add all tokens we receive at
    // once instead of refreshing for each one.
    generation = Util.readStreamInBatches(generation, { signal });

    try {
        // Read tokens as they come in.
        for await(let tokenList of generation)
        {
            let latestMessage = activeChat.value.latestMessage;
            latestMessage.appendContentBatch(tokenList);

            if(!disableGenerationAutoScroll)
            {
                // Scroll the message into view.
                let messageNode = findDOMNodeForMessage(latestMessage);
                scrollMessageNodeIntoView(messageNode);
            }
        }

        // Mark the message as finished if we reached the end.
        if(activeGenerationBackend.generationComplete)
            activeChat.value.ongoing = false;
    } finally {
        activeGenerationBackend = null;
        abortGeneration = null;
    }

    // If dialogue.value is empty, we were unmounted and cancelled during generation.
    // Just save the chat and stop.
    if(dialogue.value == null)
    {
        console.log("Unmounted during generation");
        activeChat.value.save();
        return;
    }

    // Create the user's next message and put the cursor on, and scroll to it
    // unless the user has scrolled up.
    let message = activeChat.value.createNewMessage({ role: "user" });
    let messageNode = findDOMNodeForMessage(message);

    // Enable editing on the new message, and scroll it in if auto-scrolling is enabled.
    editMessage(message, { focus: !disableGenerationAutoScroll });
    if(!disableGenerationAutoScroll)
        scrollCursorIntoView(messageNode);

    activeChat.value.save();

    // Try to create a title if we haven't yet.
    await activeChat.value.createTitle();
}

// Stop generation if it's running.
function stopGeneration()
{
    if(abortGeneration)
        abortGeneration();
}

// Ctrl-Enter: generate/cancel
onKeystroke("Enter", (e) => {
    // Ctrl-enter is a shortcut to generate.
    if(e.ctrlKey && !e.shiftKey && !e.altKey)
    {
        e.preventDefault();
        e.stopPropagation();

        if(isGenerating.value)
            stopGeneration();
        else
            beginGeneration();
    }
});

// Alt-R: retry
onKeystroke("r", (e) => {
    if(e.altKey)
    {
        e.preventDefault();
        e.stopPropagation();

        if(isGenerating.value)
            stopGeneration();
        else
            retryGeneration();
    }
});

// Ctrl-Z: undo
onKeystroke("z", (e) => {
    if(e.ctrlKey)
    {
        e.preventDefault();
        e.stopPropagation();
        activeChat.value.undo();
    }
});

// Ctrl-Y: redo
onKeystroke("y", (e) => {
    if(e.ctrlKey)
    {
        e.preventDefault();
        e.stopPropagation();

        activeChat.value.redo();
    }
});

// If we see the scroll position move up during a generation, disable auto-scroll.
// If we scroll all the way to the bottom, turn it back on again.
const windowScroll = useScroll(dialogue);
watchSyncEffect(() =>
{
    let { y } = windowScroll;
    y = y.value;

    let delta = y - scrollerLastTopPos;
    scrollerLastTopPos = y;
    if(!activeGenerationBackend)
        return;

    if(delta < 0)
    {
        if(!disableGenerationAutoScroll)
        {
            // console.warn("Disabling auto-scroll due to upwards scroll", delta);
            disableGenerationAutoScroll = true;
        }
    } else {
        // Figure out if we're scrolled past the end of the content.
        let scrollerBottomPadding = parseFloat(getComputedStyle(dialogueContainer.value).paddingBottom);
        let bottomOfScroller = document.documentElement.scrollHeight - document.documentElement.clientHeight - scrollerBottomPadding;
        let scrollerAtBottom = y >= bottomOfScroller;

        if(disableGenerationAutoScroll && scrollerAtBottom)
        {
            // console.log("Enabling auto-scroll");
            disableGenerationAutoScroll = false;
        }
    }
});

// Update scrollerLastTopPos, so any scroll that just happened won't affect disableGenerationAutoScroll.
function ignoreScrolling()
{
    let { y } = windowScroll;
    scrollerLastTopPos = y.value;
}

function editMessage(message, { focus=true }={})
{
    if(activeGenerationBackend)
    {
        console.log("Can't edit while generating");
        return;
    }

    // Only edit one message at a time.
    stopEditing();

    message.editing = true;

    let messageNode = findDOMNodeForMessage(message);
    if(focus)
        messageNode.querySelector(".editor").focus();
}

useEventListener(window, "pointerdown", (e) => {
    // Stop editing on clicks outside of the message being edited.
    if(e.target.closest(".editing") == null)
        stopEditing();
});

// Scroll up if the cursor is underneath the footer.
function scrollCursorIntoView() { Util.scrollCursorIntoView(dialogue.value, 60); }
useEventListener(document, "selectionchange", () => scrollCursorIntoView());

// Turn off any message editor and commit changes to the chat.
function stopEditing()
{
    saveEditing();

    let messages = activeChat.value.messages;
    for(let idx = 0; idx < messages.length; ++idx)
    {
        let message = messages[idx];

        // If the last message is a user message, it's the placeholder message we created
        // for the user to type in.  This message is always editable if we're not actively
        // generating.
        if(!isGenerating.value && message.role == "user")
        {
            if(idx >= messages.length - 1)
                continue;
        }

        message.editing = false;
    }

    activeChat.value.save();
}

// Save the contents of any messages being edited back to the chat.
function saveEditing()
{
    for(let node of dialogue.value.querySelectorAll(".editing"))
    {
        let newValue = node.querySelector(".editor").textContent;
        let message = findMessageForDOMNode(node);

        if(message.content == newValue)
            return;
        message.content = newValue;

        // Clear topTokens if the user modifies a message, since it won't match.  We
        // could try to sync it back up, but it's probably not worth the bother.
        message.clearTopTokens();

        updateChangedMessage(message);
    }    

    activeChat.value.save();
}

// Show MessageDropdown when a role name is clicked.  This is done on pointerdown instead
// of click, or else Safari will move the cursor and open the keyboard before we have a
// chance to stop the event.
function onDialoguePointerdown(e)
{
    onDialoguePointerdownMessageDropdown(e);
    onDialogueTokenClickPointerDown(e);
}

function onDialoguePointerdownMessageDropdown(e)
{
    let senderNode = e.target.closest(".message-sender");
    if(senderNode == null)
        senderNode = e.target.closest(".gear");
    if(senderNode == null)
        return;

    e.preventDefault();
    e.stopPropagation();

    // Hide the menu if it's already open for the same message.
    if(showingMessageDropdown.value && showingMessageDropdownAtElement.value == senderNode)
    {
        showingMessageDropdownAtElement.value = null;
        showingMessageDropdown.value = false;
        return;
    }

    closeAllDropdowns();

    let messageDomNode = senderNode.closest("[data-type='message']");
    let message = findMessageForDOMNode(messageDomNode);

    showingMessageDropdownRole.value = message.role;
    showingMessageDropdownAtElement.value = senderNode;
    showingMessageDropdown.value = true;

    // MessageDropdown calls this interface when the user performs actions.
    characterDropdownInterface.value = {
        isSystem()
        {
            return message.role == "system";
        },

        changeRole(role)
        {
            message.role = role;
            activeChat.value.save();

            // Only auto-close on role change when not in DreamGen mode.. In DreamGen,
            // leave it open to allow toggling multiple characters.
            if(!isDreamGen.value)
                this._close();
        },

        insertMessage({direction})
        {
            // If message is null, we're inserting relative to the system message at the top.
            let relativeTo = message;
            if(relativeTo == null)
            {
                relativeTo = activeChat.value.messages[0];
                direction = "up";
            }

            let newMessage = activeChat.value.createNewMessage({
                role: "user",
                position: direction == "up"? "before":"after",
                relativeTo,
            });

            activeChat.value.save();
            editMessage(newMessage);
            this._close();
        },
        
        deleteMessage()
        {
            activeChat.value.removeMessage(message);
            activeChat.value.save();
            this._close();
        },

        deleteFromHere()
        {
            activeChat.value.removeFromMessage(message);
            activeChat.value.save();
            this._close();
        },

        retryFromHere()
        {
            retryGeneration({ fromMessage: message });
            this._close();
        },
        editMessage()
        {
            editMessage(message);
            this._close();
        },

        _close()
        {
            // Clear the target element, since changes to the document will recreate nodes
            // and break the dropdown transition.
            showingMessageDropdownAtElement.value = null;
            showingMessageDropdown.value = false;
        },
    };
}

// This is called on both click and pointerdown within dialogue to work around
// v-menu weirdness.  We normally want to open on click, so we don't interfere
// with selection, but if we do that, a second click on another token will cause
// the menu to close.  The only way to prevent that seems to be to handle it in
// pointerdown.  So, we normally only react to click, but if the dropdown is
// already open we'll react on pointerdown too.
function onDialogueTokenClickPointerDown(e)
{
    if(e.type == "pointerdown")
    {
        if(e.button != 0 || !showingTokenListDropdown.value)
            return;
    }

    if(isGenerating.value)
        return;

    let tokenNode = e.target.closest("[data-logprobs]");
    if(!tokenNode)
        return;

    e.preventDefault();
    e.stopPropagation();

    closeAllDropdowns();

    // The containing message and its DOM node:
    let messageDomNode = e.target.closest("[data-type='message']");
    let message = findMessageForDOMNode(messageDomNode);

    showingTokenListDropdownAtElement.value = tokenNode;

    let logprobs = JSON.parse(tokenNode.dataset.logprobs);
    showingTokenListDropdownTokens.value = logprobs;
    showingTokenListDropdownSelectedToken.value = tokenNode.textContent;
    if(!showingTokenListDropdown.value)
        showingTokenListDropdown.value = true;

    showingTokenListDropdownInterface.value = {
        selectToken: (token) => {
            // Delete any messages after this one.
            let nextMessageIdx = activeChat.value.messages.indexOf(message);
            let nextMessage = activeChat.value.messages[nextMessageIdx+1];
            activeChat.value.removeFromMessage(nextMessage);

            // Remove any topTokens after this one, since we've removed their text.
            let tokenOffset = parseInt(tokenNode.dataset.offset);
            for(let offset of Array.from(message.topTokens.keys()))
            {
                if(offset > tokenOffset)
                    message.topTokens.delete(offset);
            }

            // Update the topTokens for this offset.
            let thisTopToken = message.topTokens.get(tokenOffset);
            console.log(`Updating topToken "${thisTopToken.token}" to "${token}"`);
            thisTopToken.token = token;

            // Cut off the message content at the beginning of the token and append the
            // new token.  This will update the view, so do it after updating topTokens.
            message.content = message.content.substring(0, tokenOffset) + token;

            // Mark the message ongoing, so it'll resume from here.
            message.ongoing = true;

            // Regenerate from here with the new token added.
            beginGeneration();
        },
    };
};

function closeAllDropdowns()
{
    showingMessageDropdownAtElement.value = null;
    showingMessageDropdown.value = false;

    showingTokenListDropdownAtElement.value = null;
    showingTokenListDropdown.value = false;
}

// When the dropdown is visible, set data-dropdown-open on the message to pin
// the gear icon so it doesn't disappear.
watchEffect(() => {
    let showingAtElement = showingMessageDropdownAtElement.value;
    let visible = showingMessageDropdown.value;
    if(!visible)
        showingAtElement = null;

    let previousDomNode = document.querySelector("[data-dropdown-open]")
    if(previousDomNode)
        delete previousDomNode.dataset.dropdownOpen;

    if(showingAtElement)
    {
        let messageDomNode = showingAtElement.closest("[data-type='message']");
        messageDomNode.dataset.dropdownOpen = "dropdownOpen";
    }
});

// Disable auto-scroll on mousewheel up, even if we're already at the top and it
// doesn't trigger a scroll.
function onDialogueWheel(e)
{
    if(e.deltaY < 0);
        disableGenerationAutoScroll = true;
}

const textSelection = useTextSelection();
watchEffect(() => {
    // Trigger this on textSelection changes.
    let selection = textSelection.selection.value;

    // Disable auto-scroll if the user makes a selection during generation.
    if(!disableGenerationAutoScroll)
    {
        // console.log("Disabling auto-scroll due to selection change");
        disableGenerationAutoScroll = true;
    }
});

// Update the undo and redo button states.
function updateUndo()
{
    canUndo.value = activeChat.value?.canUndo;
    canRedo.value = activeChat.value?.canRedo;
}

useEventListener(activeChat, "undo-changed", () => updateUndo());
useEventListener(chatDb, "entry-saved", (e) => title.value = activeChat.value?.title);

onMounted(() => {
    activeChat.value = chatDb.getById(props.chatId);

    // If our chat doesn't exist, stop early.  ChatView will move us to another chat.
    if(activeChat.value == null)
    {
        // If we're on a deleted chat, ChatView will handle it.  Stop early so we don't
        // create an editor with no chat.
        console.log(`Chat ${props.chatId} not found`);
        return;
    }

    watchSyncEffect(() => dialogue.value.classList.toggle("generating", isGenerating.value));
    
    // message-changed is received when a single message changes, and messages-changed is received
    // when messages are added or other changes happen that require a full refresh.  This is the
    // main place we see changes to the chat and update the view to reflect them.
    activeChat.value.addEventListener("message-changed", (e) => updateChangedMessage(e.message), { signal });
    activeChat.value.addEventListener("messages-changed", (e) => messagesChanged(), { signal });
    
    // Activate this chat's settings overrides.
    settings.chatLayer = activeChat.value.settingsLayer;

    // Trigger a load.
    messagesChanged();

    // If the last message in the chat is a user message, begin editing it.
    let latestMessage = activeChat.value.latestMessage;
    if(latestMessage.role == "user")
        editMessage(latestMessage);

    title.value = activeChat.value.title;

    // Scroll to the bottom.
    // TODO: figure out why this doesn't work if we don't delay it - why is the update async
    setTimeout(() => {
        let latestMessageNode = dialogueContainer.value.lastChild;
        scrollMessageNodeIntoView(latestMessageNode);
    }, 0);
});

onUnmounted(() => {
    // Stop generation if we're navigated while it's active.
    stopGeneration();

    activeChat.value = null;
    settings.chatLayer = null;
});

</script>

<template>
    <div class="root">
        <div
            ref=dialogue
            class=dialogue
            :class="{ 'dreamgen': isDreamGen.value }"
            @pointerdown="onDialoguePointerdown"
            @click="onDialogueTokenClickPointerDown"
            @wheel="onDialogueWheel"
        >
            <div ref=dialogueContainer class=dialogue-chat></div>
        </div>

        <div class=dialogue-fade>
            <div class=top-fade></div>
            <div class=bottom-fade></div>
        </div>

        <div class=input-strip>
            <InputBar
                class=ui-bar
                :isGenerating="isGenerating"
                :canUndo="canUndo"
                :canRedo="canRedo"
                :showContinueIcon="activeChat?.ongoing"
                @beginGeneration="beginGeneration"
                @stopGeneration="stopGeneration"
                @retryGeneration="retryGeneration"
                @undo="activeChat.undo()"
                @redo="activeChat.redo()"
            />
        </div>

        <!-- Dropdown menu when a message role is clicked: -->
        <v-menu
            v-model="showingMessageDropdown"
            :target="showingMessageDropdownAtElement"
            :close-on-content-click=false
            min-width="0"
        >
            <MessageDropdown
                v-model=showingMessageDropdownRole
                :interface=characterDropdownInterface
            />
        </v-menu>

        <!-- Dropdown menu when a token is clicked: -->
        <v-menu
            v-model="showingTokenListDropdown"
            :target="showingTokenListDropdownAtElement"
        >
            <TokenDropdown
                :tokens=showingTokenListDropdownTokens
                :selectedToken=showingTokenListDropdownSelectedToken
                :interface=showingTokenListDropdownInterface
            />
        </v-menu>
    </div>
</template>

<style scoped lang=scss>
.root {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    // The main scroller is on .dialogue.  Note that this prevents iOS from automatically
    // hiding the Safari nav bar.  It would be nice if that worked, but it causes all kinds
    // of problems, like position: fixed elements scrolling with the window.
    .dialogue {
        width: 100%;
        height: 100%;

        overflow-y: auto;
        scrollbar-gutter: stable;
        scrollbar-width: thin;
        touch-action: pan-y;
    }

    /* Fade the top and bottom of the screen as we approach the inset, so text fades out instead of
     * scrolling under a notch.  On the bottom, this helps anchor the UI bar. */
    .dialogue-fade {
        /* To work around culling bugs on iOS, we put the fade inside a full-height container.
         * If we just have a narrow strip at the top, it'll disappear if the user scrolls past
         * the top of the content. */
        pointer-events: none;
        position: fixed;
        inset: 0;

        .top-fade, .bottom-fade {
            position: fixed;
            content: "";
            width: 100%;
            display: block;
            z-index: 1;
            background: #000;
        }

        .top-fade {
            top: 0;
            height: calc(env(safe-area-inset-top)*1.5);
            mask: linear-gradient(to bottom, white 50%, transparent 100%);
        }
        .bottom-fade {
            bottom: 0px;
            height: calc(env(safe-area-inset-bottom)*1.5);
            mask: linear-gradient(to top, white 50%, transparent 100%);
        }
    }
}

.input-strip {
    position: fixed;
    bottom: 0px;

    padding-top: 10px;
    padding-bottom: calc(env(safe-area-inset-bottom) * 0.5);

    transition: bottom 0.2s ease-in-out;
    --hidden-y: -50px;
    --shown-y: env(safe-area-inset-bottom);
    width: 100%;
    display: flex;
    justify-content: center;

    // Don't block inputs to the scroller underneath.
    pointer-events: none;
    > * { pointer-events: auto; }
}

:deep(.dialogue-chat)
{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    min-height: 120vh;

    font-size: v-bind("chatFontSize.value + 'px'");
    line-height: v-bind("chatLineSpacing.value + 'em'");

    /* Horizontal padding is here inside the view so clicking the padding around the editor
     * focuses inside the editor. */
    width: 100%;
    --chat-max-width: v-bind("chatMaxWidth.value + 'px'");

    --horizontal-padding: calc((100% - var(--chat-max-width)) / 2);
    padding-left: max(30px, var(--horizontal-padding), env(safe-area-inset-left)); /* more padding to give the message gear button space */
    padding-right: max(10px, var(--horizontal-padding), env(safe-area-inset-right));
    padding-top: calc(100px + env(safe-area-inset-left));
    padding-bottom: calc(30vh + env(safe-area-inset-bottom));

    div[data-type="message"] {
        position: relative;
        flex-direction: column;
        align-items: flex-start;

        .formatted {
            display: flex;
            flex-direction: column;
            gap: v-bind("chatParagraphSpacing.value + 'em'");

            pre {
                line-height: 1.4em;
                font-size: v-bind("chatFontSize.value*.8 + 'px'");
                text-wrap: wrap;
            }

            q {
                font-style: italic;

                // Remove quotes from <q>.  We include them in the text.  With the default behavior
                // copied text won't include the quotes, which is almost never what you actually want.
                &:before { content: ""; }
                &:after { content: ""; }
            }

            [data-logprobs]:hover {
                background-color: #224;
            }
        }
    }

    // Hide the gear, so we only show the display-names list.
    .dialogue.dreamgen & .gear {
        display: none;
    }

    .dialogue & .show-with-cursor {
        // Don't set display: none, since the context menu transition will get confused.
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
    }

    .dialogue & div[data-type="message"][data-dropdown-open] .show-with-cursor,
    .dialogue & div[data-type="message"].editing             .show-with-cursor,
    .dialogue & div[data-type="message"]:hover               .show-with-cursor
    {
        opacity: 1;
    }

    .dialogue & div[data-type="message"].editing
    {
        // border-left: 1px solid #babfe7;
        padding-left: .5em;
    }

    // In regular (non-DreamGen) mode, this is shown as a gear to the left of the message
    // which is only displayed while focus is in the message.
    .dialogue:not(.dreamgen) & .message-sender {
        // Hide the display-names list, so we only show the gear.
        display: none;
        background-color: #000;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0%;
        right: calc(100% + 5px);
        display: flex;
        .gear, .edit
        {
            font-family: 'Material Design Icons';
            position: relative;
            cursor: pointer;

            // Enlarge the touchable area:
            &::before {
                content: "";
                position: absolute;
                inset: -10px;
            }
        }

        .edit:after { content: "\F03EB"; } // pencil
        .gear:after { content: "\2699"; } // cog
    }

    [data-type='message'] {
        /* Only display one of .formatted and .editor: */
        &.editing .formatted { display: none; }
        &:not(.editing) .editor { display: none; }

        * {
            user-select: text;
            -webkit-user-select: text;
        }
    }

    [data-type="message"][data-ongoing] .gear:after {
        content: "\F01D9";
    }

    // [data-type="message"][data-purged] { color: #aaa; }

    div[data-role="system"] {
        /* display: none; */
        color: #b79be8;
        border-left: 1px solid #babfe7;
        padding-left: .5em;
    }

    div[data-role="user"] {
        color: #babfe7;
        border-left: 1px solid #babfe7;
        border-left-style: dashed;
        padding-left: .5em;
        // background-color: #040420;
    }

    .message-sender {
        font-weight: bold;
    }
    
    [data-type='message'] .message-sender {
        cursor: pointer;
    }
}
</style>
