<template>
    <v-select 
        v-model="selectedCharacters"
        density=compact variant="plain"
        hide-details multiple chips
        label="Characters"
        :items="characterNames"
    >
        <template v-slot:item="{ item, props }">
            <v-list-item v-bind="props">
                <v-list-item-title v-text="item.text" />
            </v-list-item>
        </template>
    </v-select>
</template>

<script setup>
import settings from "@/misc/settings.js";
import { ref, watch } from 'vue';
import { useCharacterNames } from "@/misc/effects.js";
import InstructTemplate from "@/ai/instruct-template.js";

const props = defineProps({
    chatId: { type: Number },
});

const selectedCharacters = ref([]);

// The list of names we show in the dropdown: availableCharacterNames plus any selected
// names that aren't in that list.
const characterNames = ref([]);

// The list of names that are available in the current chat.
const availableCharacterNames = useCharacterNames();
function updateAvailableCharacterNames()
{
    let names = Array.from(availableCharacterNames.value);

    // Add "*" to the beginning if we're in DreamGen mode.
    let instructTemplate = InstructTemplate.getActiveTemplate();
    if(instructTemplate?.dreamGen)
        names = ["*", ...names];

    // If any names are left selected that aren't in the list, add them to the list so
    // they can be deselected.  We'll refresh and remove them from the list if they are.
    for(let name of selectedCharacters.value)
    {
        if(!names.includes(name))
            names.push(name);
    }

    characterNames.value = names;
};
watch(availableCharacterNames, () => updateAvailableCharacterNames());
updateAvailableCharacterNames();

// The selection is saved on the activeRoles setting.  Load it normally, but save
// it to the chat settings layer.
function loadFromSetting() { selectedCharacters.value = settings.values.activeRoles; }
loadFromSetting();
settings.watch("activeRoles", () => loadFromSetting());

watch(selectedCharacters, () => {
    // Ignore changes when we don't have a chat settings layer yet during initialization.
    if(settings.chatLayer == null)
        return;

    settings.chatLayer.set("activeRoles", Array.from(selectedCharacters.value));
});
</script>

<style scoped>
</style>
