export default class GenerationBackend
{
    async getModelName({ signal }={}) { throw new Error("Not implemented"); }
    async getMaxContextLength() { throw new Error("Not implemented"); }
    async getTokenCount(prompt) { throw new Error("Not implemented"); }
    async tokenizeString(prompt) { throw new Error("Not implemented"); }
    get generationComplete() { throw new Error("Not implemented"); }

    async *generate({
        prompt='',
        seed=null,
        signal=null,
    }) { throw new Error("Not implemented"); }
}
