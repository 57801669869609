import { shallowReactive, watch } from 'vue';
import { useAbort } from '@/misc/util';

// This implements a layer-based settings system, modelled broadly after VS Code's.
// Each settings layer can store a value for each setting, which will override the
// value set by earlier layers:
//
// - defaults: the user's defaults.  This is where general prefernces that aren't
// specific to a particular configuration are stored.
// - model: This is saved based on the currently active model.  Settings that are
// specific to the model are set here, such as the context size.
// - chat: This is saved with the chat, and overrides everything else.  If you want
// a chat to have a higher or lower temperature than default, you can do that here.
// If the system prompt is edited in the chat, it'll also be saved here.
//
// These are each a SettingsLayer, and you can set a SettingsLayer for each type.
// "settings.values.settingName" will return the final value for a setting.

class Settings extends EventTarget
{
    constructor()
    {
        super();

        this.allSettings = new Map();

        // The currently selected layers:
        this._layers = {
            defaults: null,
            model: null,
            chat: null,
        };

        // SettingsLayers which are currently active:
        this.loadedLayers = [];

        this.values = new Proxy({}, {
            get: (target, name) => this.getValue(name),
            ownKeys: () => Array.from(this.allSettings.keys()),
            getOwnPropertyDescriptor: (target, name) => ({
                enumerable: true,
                configurable: true,
                value: target[name]
            }),
        });

        this.defaults = new Proxy({}, {
            get: (target, name) => this.getDefinition(name).defaultValue,
            ownKeys: () => Array.from(this.allSettings.keys()),
        });
    }

    // Fire an event with the name of the setting when a setting changes.
    settingChanged(name) { this.dispatchEvent(new Event(name)); }

    // Register a setting.
    register(name, {
        defaultValue, type, desc,

        // If true, the settings dialog will make itself transparent while editing this setting.
        // This is used for cosmetic settings so the effects are visible while editing.
        dimDialog,

        min, max, step=1, int=false, // for numbers
        multiline=false, // for strings
    })
    {
        let definition = { defaultValue, type, desc, dimDialog, min, max, step, int, multiline };
        this.allSettings.set(name, definition);
    }

    // If all settings may have changed, such as layers being loaded, fire an event
    // for each setting.
    _allSettingsChanged()
    {
        for(let name of this.allSettings.keys())
            this.settingChanged(name);
    }

    _queueAllSettingsChanged()
    {
        setTimeout(() => this._allSettingsChanged(), 0);
    }

    getDefinition(name)
    {
        return this.allSettings.get(name);
    }

    // Get the active layer with the given type.
    getLayer(name) { return this._layers[name]; }
    get layerNames() { return Object.keys(this._layers); }

    // Get all active layers.
    getLayers()
    {
        let layers = [];
        for(let layerName of this.layerNames)
            if(this._layers[layerName])
                layers.push(this._layers[layerName]);

        return layers;
    }

    _setLayer(layer, name)
    {
        if(this._layers[name] === layer)
            return;

        this._layers[name] = layer;
        this._queueAllSettingsChanged();
    }

    // Return all layer names before LayerName which modify the given setting.  These
    // are settings that would be overridden by layerName.
    earlierLayersThatOverride(settingName, layerName)
    {
        let results = [];
        let keys = Object.keys(this._layers);
        let layerIdx = keys.indexOf(layerName);
        console.assert(layerIdx != -1, layerName);
        for(let idx = 0; idx <= layerIdx - 1; ++idx)
        {
            let earlierLayerName = keys[idx];
            let layer = this._layers[earlierLayerName];
            if(layer && layer.has(settingName))
                results.push(earlierLayerName);
        }

        return results;
    }

    // Return all layer names after layerName which modify the given setting.  These
    // layers override any setting in layerName.
    laterLayersThatOverride(settingName, layerName)
    {
        let results = [];
        let keys = Object.keys(this._layers);
        let layerIdx = keys.indexOf(layerName);
        console.assert(layerIdx != -1, layerName);
        for(let idx = layerIdx + 1; idx < keys.length; ++idx)
        {
            let laterLayerName = keys[idx];
            let layer = this._layers[laterLayerName];
            if(layer && layer.has(settingName))
                results.push(laterLayerName);
        }

        return results;
    }

    // Return the layer names for all layers that modify the given setting.
    getLayersWithValue(settingName)
    {
        let layerNames = [];
        for(let [layerName, layer] of Object.entries(this._layers))
        {
            if(layer && layer.has(settingName))
                layerNames.push(layerName);
        }

        return layerNames;
    }

    // Get and set the active SettingsLayer for each layer.
    set defaultLayer(layer) { this._setLayer(layer, "defaults"); }
    get defaultLayer() { return this.getLayer("defaults"); }
    set modelLayer(layer) { this._setLayer(layer, "model"); }
    get modelLayer() { return this.getLayer("model"); }
    set chatLayer(layer) { this._setLayer(layer, "chat"); }
    get chatLayer() { return this.getLayer("chat"); }

    // Get the final value for the given setting.
    getValue(settingName, {
        // If set, start looking for a value at the given layer name.  For example,
        // if this is "model", we'll look at model and defaults, ignoring chat
        // settings.
        startAtLayer=null,
    }={})
    {
        if(startAtLayer != null)
            console.assert(this.layerNames.includes(startAtLayer), startAtLayer);

        let def = this.getDefinition(settingName);
        if(def == null)
        {
            console.log(settingName);
            throw new Error(`Setting ${settingName} not found`);
        }
        let started = startAtLayer == null;
        for(let layerName of this.layerNames.toReversed())
        {
            if(!started)
            {
                if(layerName == startAtLayer)
                    started = true;
                else
                    continue;
            }

            let layer = this._layers[layerName];
            if(!layer || !layer.has(settingName))
                continue;

            return layer.get(settingName);
        }

        return def.defaultValue;
    }

    use(settingName)
    {
        const signal = useAbort();

        const value = shallowReactive({ value: this.values[settingName] });
        this.addEventListener(settingName, () => value.value = this.values[settingName], { signal });

        return value;
    }

    watch(settingName, callback, { sync=false }={})
    {
        const signal = useAbort();

        const value = shallowReactive({ value: this.values[settingName] });
        this.addEventListener(settingName, () => value.value = this.values[settingName], { signal });

        watch(value, () => callback(value.value), { flush: sync? "sync":"async" });
        return watch;
    }

    watchSync(settingName, callback)
    {
        return this.watch(settingName, callback, { sync: true });
    }
}

// Export a singleton.
const settings = new Settings();
export default settings;

// An active settings layer, with a set of overridden settings.  This can be
// saved using the SavedSettingsLayer subclass below, or overridden for saving
// into chats.
export class SettingsLayer
{
    constructor({
        settings={},
    }={})
    {
        this._settings = new Map(Object.entries(settings));
    }

    toJSON()
    {
        return Object.fromEntries(this._settings);
    }

    save()
    {
        throw new Error("Not implemented");
    }

    set(name, value)
    {
        let { type, int } = settings.getDefinition(name);
        if(type == "number")
        {
            value = parseFloat(value);
            if(isNaN(value))
                throw new Error(`Setting ${name} must be a number, got ${value}`);
            if(int)
                value = Math.round(value);
        }

        let oldValue = this._settings.get(name);
        if(JSON.stringify(value) == JSON.stringify(oldValue))
            return;

        this._settings.set(name, value);
        this.save();

        settings.settingChanged(name);
    }

    get(name) { return this._settings.get(name); }
    has(name) { return this._settings.has(name); }
    unset(name)
    {
        this._settings.delete(name);
        this.save();
        settings.settingChanged(name);
    }

    copySettingsFromTemplate(otherTemplate)
    {
        this._settings = new Map(otherTemplate._settings);
    }
}

// A SettingsLayer which is stored to localStorage.
export class SavedSettingsLayer extends SettingsLayer
{
    constructor(name, args)
    {
        super(args);

        if(name == "")
            throw new Error("Name can't be blank");

        this._name = name;
    }

    // If this is a template or a preset, return the display name.
    get displayName()
    {
        if(this._name.startsWith("template/"))
            return this._name.slice("template/".length);
        else if(this._name.startsWith("preset/"))
            return this._name.slice("preset/".length);
        else
            return this._name;
    }

    // Load a named settings layer.
    //
    // The chat layer is stored in the saved chat.
    static load(name)
    {
        // If this doesn't exist, we'll create a new empty layer.
        let data = localStorage.getItem(`settings/${name}`);
        let settings = data? JSON.parse(data):undefined;
        return new SavedSettingsLayer(name, { settings });
    }

    // Return all saved layer names.
    static getAllSavedNames()
    {
        let keys = Object.keys(localStorage);
        let layerKeys = keys.filter((key) => key.startsWith("settings/"));
        return layerKeys.map((key) => key.slice("settings/".length));
    }

    // Model templates and chat presets are the same thing to us, just with different
    // names: model templates start with template and chat presets start with presets.
    // Return all saved layer names that begin with "template/", with the prefix stripped off,
    // which are model templates that can be assigned to models.
    static _getAllWithPrefix(prefix)
    {
        let keys = this.getAllSavedNames();
        let layerKeys = keys.filter((key) => key.startsWith(prefix));
        let layerNames = layerKeys.map((key) => key.slice(prefix.length));
        return layerNames;
    }

    static loadTemplate(name) { return SavedSettingsLayer.load(`template/${name}`); }
    static loadPreset(name) { return SavedSettingsLayer.load(`preset/${name}`); }

    static getAllModelTemplateNames() { return this._getAllWithPrefix("template/"); }
    static getAllChatPresetNames() { return this._getAllWithPrefix("preset/"); }

    // Return the localStorage key to use for the given layer name.
    _settingKeyForName(name) { return `settings/${name}`; }

    save()
    {
        let key = this._settingKeyForName(this._name);
        localStorage[key] = JSON.stringify(this.toJSON());
    }

    get name() { return this._name; }

    delete()
    {
        let key = this._settingKeyForName(this._name);
        delete localStorage[key];
    }

    // Rename this layer to newName.  Return true on success, or false if the
    // name was already in use.
    rename(newName)
    {
        if(newName == "")
            throw new Error("Name can't be blank");

        // Make sure newName doesn't already exist.
        let oldKey = this._settingKeyForName(this._name);
        let newKey = this._settingKeyForName(newName);
        if(localStorage[newKey])
        {
            console.log(`Key ${key} already exists`);
            return false;
        }

        localStorage[newKey] = JSON.stringify(this.toJSON());
        delete localStorage[oldKey];
    }
}

// Register settings:
//
// Global settings
settings.register("serverUrl", { defaultValue: "http://10.0.0.9:15011/", type: "string", desc: "Server URL" });

// Generation settings
settings.register("temperature", { defaultValue: 1.3, type: "number", min: 0.5, max: 4, step: 0.05, 
    desc: "Generation temperature",
});
settings.register("contextSize", { defaultValue: 2048, type: "number", min: 256, max: 128*1024, step: 256,
    desc: "Context size",
    int: true,
});
settings.register("maxOutputLength", { defaultValue: 250, type: "number", min: 1, max: 1024*16, step: 1,
    desc: "Output length",
    int: true,
});
settings.register("topK", { defaultValue: 100, type: "number", min: 0, max: 200, int: true, desc: "Top K" });
settings.register("topP", { defaultValue: 0.92, type: "number", min: 0, max: 1, step: 0.01, desc: "Top P" });
settings.register("topA", { defaultValue: 0, type: "number", min: 0, max: 1, step: 0.01, desc: "Top A" });
settings.register("minP", { defaultValue: 0, type: "number", min: 0, max: 1, step: 0.01, desc: "Min P" });
settings.register("typicalP", { defaultValue: 1.0, type: "number", min: 0, max: 1, step: 0.01, desc: "Typical P" });
settings.register("tfs", { defaultValue: 1.0, type: "number", min: 0, max: 1, step: 0.01, desc: "Tail-Free Sampling" });
settings.register("repetitionPenalty", { defaultValue: 1.1, type: "number", min: 1, max: 3, step: 0.01, desc: "Repetition penalty" });
settings.register("repetitionPenaltyRange", { defaultValue: 256, type: "number", desc: "Repitition penalty range" });
settings.register("samplerOrder", { defaultValue: [6,0,1,3,4,2,5], type: "object", desc: "Sampler order" });
settings.register("grammar", { defaultValue: "", type: "string", desc: "GBNF Grammar" });
settings.register("instructTemplate", { defaultValue: "Llama 3", type: "special",  multiline: true, desc: "Instruct template" });

// Chat-specific
//
// This is managed by CharacterSelector, and is only saved to the chat layer.
settings.register("activeRoles", { defaultValue: [], type: "special", desc: "Active role" });

// System prompt
settings.register("systemPrompt", { defaultValue: "You are a happy AI.", type: "string", multiline: true, desc: "System prompt" });

// Display
//
// dimDialog tells the settings dialog to dim itself while dragging, so the user can see the
// effect as he changes the setting.
settings.register("chatFontSize", { defaultValue: 24, type: "number", min: 12, max: 40, step: 1, dimDialog: true, desc: "Chat font size" });
settings.register("chatLineSpacing", { defaultValue: 1.3, type: "number", min: 1, max: 2, step: 0.1, dimDialog: true, desc: "Line spacing" });
settings.register("chatParagraphSpacing", { defaultValue: 0.7, type: "number", min: 0, max: 1, step: 0.1, dimDialog: true, desc: "Paragraph spacing" });
settings.register("chatMaxWidth", { defaultValue: 1000, type: "number", min: 400, max: 4000, step: 100, dimDialog: true, desc: "Chat width" });

// This prompt works well for Llama 3 8B.  It tries to discourage things like prefixing with "Sure",
// giving multiple answers, or going off on a tangent about how it loves making titles.
// It probably won't work as well for simpler models, and it might have trouble if the
// user's system prompt is strongly pushing the model to do something else.  We could override
// the system prompt, but that could cause other problems (it could cause a large prompt
// reprocessing or cause alignment changes).  "Don't describe it as a conversation" tries
// to discourage redundant titles like "Discussion about *topic*".
settings.register("autoTitle", { defaultValue: true, type: "boolean", desc: "Automatic titles" });
settings.register("autoTitlePrompt", {
    type: "string", multiline: true, desc: "Automatic title prompt",
    defaultValue: "Create a brief snippet describing the above in fewer than four words.  Answer with a single line, putting the snippet in quotes.  Omit preamble, commentary and discussion.  Only answer with a single snippet.",
});

// Load the defaults layer.  This is always available.
settings.defaultLayer = SavedSettingsLayer.load("defaults");
settings.modelLayer = SavedSettingsLayer.loadTemplate("default");
