import './assets/main.scss'

import { createApp } from 'vue'
import App from './App.vue'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { createRouter, createWebHashHistory } from 'vue-router'

import ChatView from '@/views/chat/ChatView.vue'
import AboutView from '@/views/AboutView.vue'
import { chatDb } from '@/misc/chat'
import characterDb from "@/storytelling/characters.js";

// Load data before continuing.
await characterDb.load();
await chatDb.load();

navigator.storage?.persist?.();

const app = createApp(App)

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [{
        path: "/",
        name: "home",
        component: ChatView,
    }, {
        // /chat without an ID will select one and navigate to /chat/:id below.
        path: "/chat",
        name: "selectChat",
        component: ChatView,
    }, {
        path: "/chat/:id",
        name: "chat",
        component: ChatView,
        props: route => ({ chatId: parseInt(route.params.id) }),
    },
    {
        path: "/about",
        name: "about",
        component: AboutView,
    }],
});
app.use(router);

const theme =
{
    dark: true,
    colors: {
        background: '#02030b',
        surface: '#04061b',
        primary: '#1867C0',
        // "surface-variant": "#101080",
        // "on-surface-variant": "#fff",
    },
  
    variables: {
    },
}

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'theme',
        themes: {
            theme,
        },
        variations: {
            colors: ["background", "primary", "secondary"],
            lighten: 1,
            darken: 1,
        },
    },
});

app.use(vuetify);
app.mount('#app');
