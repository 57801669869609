<template>
    <Dialog ref=dialog title="Settings">
        <template v-slot:contents>
            <v-tabs v-model="currentTabIndex" style="flex-shrink: 0;">
                <v-tab
                    selected-class="selected"
                    slim
                    v-for="(item, index) in pages" :key="index"
                    v-text='item.label'
                    :value="item.index"
                />
            </v-tabs>

            <div class=scroller>
                <component
                    :is="pages[currentTabIndex].type"
                    :currentTab="pages[currentTabIndex].value"
                />
            </div>
        </template>
    </Dialog>
</template>
    
<script setup>
import { ref } from 'vue';

import Dialog from "./Dialog.vue";
import SettingsDialogList from "./SettingsDialogList.vue";
import SettingsDialogInstruct from "./SettingsDialogInstruct.vue";
import SettingsDialogStory from "./SettingsDialogStory.vue";

const dialog = ref(null);
const currentTabIndex = ref(0);

const pages = [
    {
        value: "global",
        label: "Global",
        type: SettingsDialogList,
    }, {
        value: "defaults",
        label: "Defaults",
        type: SettingsDialogList,
    }, {
        value: "model",
        label: "Model",
        type: SettingsDialogList,
    }, {
        value: "chat",
        label: "Chat",
        type: SettingsDialogList,
    }, {
        value: "instruct",
        label: "Instruct",
        type: SettingsDialogInstruct,
    }, {
        value: "story",
        label: "Story",
        type: SettingsDialogStory,
    },
];

defineExpose({
    showDialog: () => dialog.value.showDialog(),
});
</script>

<style scoped>
.scroller {
    overflow-y: auto;
    height: 100%;
    padding: 0 20px;
}
.selected {
    background-color: #fff;
    background: #44e;
}
</style>
