<template>
    <div style="display: flex; flex-wrap: wrap;">
        <v-card-text>{{ descriptions[props.currentTab] }}</v-card-text>
    </div>

    <div
        v-for="(item, index) in items"
        :key="index"
    >
        <SettingRow
            :setting="item"
            :layer="viewingLayer"
            :key="item"
            :disabled="instructTemplateSettings.indexOf(item) != -1"
        />
    </div>
</template>
    
<script setup>
import { ref, watch } from 'vue';

import SettingRow from "./SettingRow.vue";

const dialog = ref(null);

const props = defineProps({
    currentTab: { type: String },
});

defineExpose({
    showDialog: () => dialog.value.showDialog(),
});

const viewingLayer = ref("defaults");

const descriptions = {
    global: "",
    defaults: "These settings are used if they're not overridden by the model or chat.",
    model: "These settings are saved to the model, and override defaults.  They can be overridden by chat settings.",
    chat: "These settings are saved to the chat.  You can make chat-specific adjustments here.",
};

const instructTemplateSettings = [
    "instructSystemPrefix",
    "instructSystemSuffix",
    "instructUserPrefix",
    "instructUserSuffix",
    "instructAssistantPrefix",
    "instructAssistantSuffix",
    "stopSequence",
];

let items = ref([]);

function loadItems()
{
    if(props.currentTab == "global")
    {
        items.value = [
            "serverUrl",
            "chatFontSize",
            "chatLineSpacing",
            "chatParagraphSpacing",
            "chatMaxWidth",
            "autoTitle",
            "autoTitlePrompt",
        ];

        // These settings are only stored globally for now.
        viewingLayer.value = "defaults";
    }
    else if(props.currentTab == 'defaults' || props.currentTab == 'model' || props.currentTab == 'chat')
    {
        viewingLayer.value = props.currentTab;
        
        items.value = [
            "systemPrompt",
            "instructTemplate",
            "temperature",
            "contextSize",
            "maxOutputLength",
            "topK",
            "topP",
            "topA",
            "minP",
            "typicalP",
            "tfs",
            "repetitionPenalty",
            "repetitionPenaltyRange",
        ];
    }
}
loadItems();

watch(props, () => loadItems());
</script>
