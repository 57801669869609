<template>
    <v-list :lines="false" density="compact">
        <v-list-item
            v-for="(item, i) in Object.entries(props.tokens)" :key="i"
            @click="(event) => props.interface.selectToken(item[0])"
            :active="item[0] == props.selectedToken"
        >
            <v-list-item-title>
                <div class=item>
                    <div style="flex: 1">
                        {{ item[0] }}
                    </div>
                    <div>
                        {{ (item[1] * 100).toFixed(2) }}
                    </div>
                </div>
            </v-list-item-title>
        </v-list-item>
    </v-list>
</template>

<script setup>
const props = defineProps({
    tokens: { type: Object },
    selectedToken: { type: String },
    interface: { type: Object },
});
</script>

<style scoped>
.item
{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
}
</style>