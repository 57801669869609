import GenerationBackend from "./backend.js";
import * as Util from "../misc/util.js";
import settings from "../misc/settings.js";
import InstructTemplate from "@/ai/instruct-template.js";

// Generate a random-ish ASCII string to use as an identifier.  We should be able to
// use crypto.randomUUID, but browsers don't allow local networks to be secure.
const genkey = Math.round(Math.random() * 1000000000).toString();

export default class GenerationBackendTextGen extends GenerationBackend
{
    constructor()
    {
        super();

        this._generationComplete = false;
    }

    get url() { return settings.values.serverUrl.replace(/\/$/, ""); }
    get generationComplete() { return this._generationComplete; }

    async getModelName({ signal }={})
    {
        let { model_name: model } = await this.get("v1/internal/model/info", { signal });
        return model;
    }

    // This doesn't exist?
    async getMaxContextLength()
    {
        return 4096;
        let { value: tokens } = await this.get("api/v1/config/max_context_length");
        return tokens;
    }

    async getTokenCount(text)
    {
        let { tokens } = await this.post("v1/internal/encode", {
            text
        });

        return tokens.length;
    }

    async tokenizeString(text)
    {
        let { tokens } = await this.post("v1/internal/encode", {
            text
        });
        return tokens;
    }

    async *generate({
        prompt='',

        // The number of tokens to allow in the response.  This is calculated
        // by Chat.getPrompt() while assembling the prompt.
        availableContext,

        // If set, specify a generation seed.
        seed=null,

        settingsOverrides={},
        signal=null,
    }={})
    {
        signal ??= (new AbortController()).signal;

        signal.throwIfAborted();

        // Combine 
        let effectiveSettings = {
            ...settings.values,
            ...settingsOverrides,
        };

        // Gather settings.
        let {
            contextSize, temperature,
            topK, topP, topA, minP, typicalP, tfs,
            repetitionPenalty, repetitionPenaltyRange,
            grammar,
        } = effectiveSettings;

        if(seed === null)
            seed = -1;

        let instructTemplate = InstructTemplate.getActiveTemplate();

        let stopSequences = [];
        if(instructTemplate.stopSequence?.length > 0)
            stopSequences.push(instructTemplate.stopSequence);

        // API arguments:
        let args = {
            logprobs: 10,
            max_context_length: contextSize,
            max_tokens: availableContext,

            temperature,
            top_p: topP,
            top_k: topK,
            top_a: topA,
            min_p: minP,

            typical_p: typicalP,
            tfs,
            repetition_penalty: repetitionPenalty,
            repetition_penalty_range: repetitionPenaltyRange,
            stop: stopSequences,

            // smoothing_factor: 0,
            grammar_string: grammar,
            seed,
            prompt,

            // We don't normally expect to see special tokens in the output that aren't
            // stop tokens.  If we do, something is probably wrong with the instruct setup.
            // They should be included in the output so it's obvious if something is wrong.
            skip_special_tokens: false,

            // logit_bias,
            // presence_penalty: 0,
            // dynatemp_range: 1,
            // dynatemp_exponent: 5,
            // dynatemp_exponent,

            // dynamic_temperature: bool = False
            // dynamic_temperature_low: float = 0.1
            // epsilon_cutoff: float = 0
            // eta_cutoff: float = 0
            // guidance_scale: float = 1
            // negative_prompt: str = ''
            // penalty_alpha: float = 0
            // mirostat_mode: int = 0
            // mirostat_tau: float = 5
            // mirostat_eta: float = 0.1
            // temperature_last: bool = False
            // do_sample: bool = True
            // encoder_repetition_penalty: float = 1
            // no_repeat_ngram_size: int = 0
            // truncation_length: int = 0
            // max_tokens_second: int = 0
            // custom_token_bans: str = ""
            // frequency_penalty: float | None = 0
            // logit_bias: dict | None = None
            // presence_penalty: float | None = 0
        };

        try {
            let response = await this.beginGeneration(args, { signal });
            if(!response)
            {
                console.log("Connection error during generation");
                return;
            }

            let reader = response.body.getReader();
            for await(let { data, type } of Util.eventStreamReader(reader, { signal }))
            {
                if(type != "message")
                    continue;
                if(data.length == 0)
                    continue;

                let { choices } = data[0];
                console.assert(choices.length == 1);
                let { finish_reason: finishReason, text: token, logprobs } = choices[0];
                let { top_logprobs: logProbs } = logprobs;
                if(finishReason)
                {
                    // "stop": stop token
                    // "length": out of tokens
                    this._generationComplete = finishReason == "stop";
                    break;
                }

                // The first message is always empty.  This is probably sent after prompt
                // processing finishes.
                if(token.length == 0)
                    continue;

                // We expect to get one token and one logProbs result.
                console.assert(logProbs.length == 1);

                let topTokens = Util.logProbsToTopTokens(logProbs[0]);

                yield { token, topTokens };
            }
        } catch(e) {
            // On error, abort generation.  Don't wait for this to finish.
            console.log("Cancelling generation due to error:", e);
            this._generationComplete = false;
            throw e;
        }

        // If the abort signal was fired, make sure generation is stopped server-side.
        // We don't wait for this to complete.
        if(signal.aborted)
        {
            this._generationComplete = false;
            return;
        }
    }

    // This isn't generalized yet.
    async beginGeneration(args, { signal }={})
    {
        args = {
            ...args,
            genkey,
        };

        return await this.post("v1/completions", {
            ...args,
            json: false,
            stream: true,
            signal,
        });
    }

    async request(path, {
        method="POST",
        signal=null,
        json=true,
        ...args
    })
    {
        signal ??= (new AbortController()).signal;
        let requestUrl = `${this.url}/${path}`;
        let options = {
            method,
            headers: {
                "Content-Type": "application/json",
            },
            signal,
        };

        if(method == "POST")
        {
            options.body = JSON.stringify(args);
        }

        let response;
        try {
            response = await fetch(requestUrl, options);

            // For EventStream requests, a 422 response has a JSON error response.  It's an overcomplicated
            // schema-based response that makes it needlessly hard to extract a simple error message out of.
            // Don't use JSON schemas.
            if(!json && response.status == 422)
            {
                let error = await response.json();
                let { detail } = error;
                console.error("Error:", detail[0]);
                return null;
            }
        } catch(e) {
            if(!signal?.aborted)
                console.log(`Connection failed: ${path}`);
            return json? { }: null;
        }

        if(!json)
            return response;
        try {
            return await response.json();
        } catch(e) {
            console.log(`Error parsing JSON from request ${path}`, e);
            return { };
        }
    }

    async get(path, { ...args }={})
    {
        return this.request(path, {
            method: "GET",
            ...args
        });
    }

    async post(path, { ...args }={})
    {
        return this.request(path, {
            method: "POST",
            ...args
        });
    }
}
