<template>
    <v-list-item
        class="new-chat chat-item"
        @click="createNewChat()"
    >
        <v-list-item-title>Create new chat</v-list-item-title>

        <template v-slot:prepend>
        </template>
        <template v-slot:append>
            <v-btn icon="mdi-menu-left" density="compact"
                @click.stop="chatsDrawerVisible = false;"
            />
        </template>
    </v-list-item>

    <v-list-item
        v-for="(chat, index) in chats" :key="index"
        class=chat-item
        :class="{
            'active-chat': chat.id === currentChatId,
            'menu-open': showingDropdown && showingDropdownForChat === chat,
            'selected': chat.id === currentChatId,
        }"
        :ripple=false
        tag="router-link"
        touchless
        :to="`/chat/${chat.id}`"
        @click='hideDropdown()'
    >
        <v-list-item-title>{{ chat.title  }}</v-list-item-title>

        <!-- Dropdown button -->
        <v-btn class="dropdown-button" icon="mdi-menu-down" density="compact"
            @click="(event) => showDropdown(chat, event)"
        />
    </v-list-item>

    <!-- Dropdown -->
    <v-menu
        ref="dropdown"
        v-model="showingDropdown"
        :target="showingDropdownAtElement"
    >
        <v-list :lines="false" density="compact" nav>
            <v-list-item
                v-for="(item, i) in dropdownItems" :key="i" :value="item"
                @click="(event) => item.action(showingDropdownForChat, event)"
            >
                <template v-slot:prepend>
                    <v-icon :icon="item.icon"></v-icon>
                </template>

                <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
        
<script setup>
import { computed, ref, onMounted, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAbort } from '@/misc/util';
import { Chat } from '@/misc/chat'
import { chatDb } from '@/misc/chat'

const router = useRouter();
const route = useRoute();
const signal = useAbort();
const chats = ref([]);
const dropdown = ref(false);
const showingDropdown = ref(false);
const showingDropdownForChat = ref(null);
const showingDropdownAtElement = ref(null);
const currentChatId = computed(() => parseInt(route.params.id));

function loadChats()
{
    chats.value = chatDb.getAll();
}

onMounted(() =>
{
    chatDb.addEventListener("entry-saved", () => loadChats(), { signal });
    chatDb.addEventListener("entry-list-changed", () => loadChats(), { signal });
    loadChats();
});
const showConfirmation = inject('showConfirmationDialog');
const showTextEntryDialog = inject('showTextEntryDialog');
const showMessage = inject('showMessage');
const chatsDrawerVisible = inject('chatsDrawerVisible');

function hideDropdown()
{
    showingDropdown.value = false;
}

const dropdownItems = [{
    text: "Rename", icon: "mdi-pencil",
    action: async(chat) => {
        let newTitle = await showTextEntryDialog({
            title: "Enter new name",
            oldValue: chat.title,
        });
        if(newTitle == null || newTitle === chat.title)
            return;

        console.log(`Renaming chat ${chat.id} from ${chat.title} to ${newTitle}`);
        chat.title = newTitle;
        console.log("save");
        await chat.save();
    },
}, {
    text: 'Create title', icon: 'mdi-auto-fix',
    action: async(chat) => {
        chat.createTitle({force: true});
    },
}, {
    text: 'Delete', icon: 'mdi-delete',
    action: async(chat, event) => {
        if(!event.shiftKey)
        {
            let result = await showConfirmation({ title: "Delete this chat?", text: chat.title });
            if(!result)
                return;
        }
        
        // If we're deleting the current chat, pick a chat to switch to.
        let navigateToChatId = currentChatId.value;
        if(chat.id == currentChatId.value)
        {
            let availableChats = chatDb.getAll();
            let ourIndex = availableChats.findIndex((c) => c.id === chat.id);
            if(ourIndex < availableChats.length - 1)
                ourIndex++;
            navigateToChatId = availableChats[ourIndex].id;
        }

        console.log(`Deleting chat ${chat.id}`);
        await chatDb.delete(chat.id);
    },
}, {
    text: 'Duplicate', icon: 'mdi-content-copy',
    action: async(chat) => {
        console.log("duplicate");
        let newChat = chat.copy();
        await newChat.save();
        console.log(`Copied chat ${chat.id} to ${newChat.id}`);
        router.push({ name: 'chat', params: { id: newChat.id } });

        chatsDrawerVisible.value = false;
    },
}];

function showDropdown(chat, event)
{
    event.stopPropagation();
    event.preventDefault();

    if(showingDropdown.value)
    {
        showingDropdown.value = false;
        return;
    }

    showingDropdown.value = true;
    showingDropdownForChat.value = chat;
    showingDropdownAtElement.value = event.target;
}

// Create a new chat and navigate to it.
async function createNewChat()
{
    let chat = Chat.defaultChat();
    await chat.save();
    console.log(`Created new chat: ${chat.id}`);
    router.push({ name: 'chat', params: { id: chat.id } });
    chatsDrawerVisible.value = false;
}

</script>

<style scoped>
.chat-item
{
    position: relative;
    background-color: rgb(var(--v-theme-background));
    /* &:hover { background-color: rgb(var(--v-theme-background-lighten-1)); } */
    /* touch-action: none; */

    .dropdown-button
    {
        position: absolute; right: 0;
        top: 50%;
        transform: translateY(-50%);
        /* display: none; */
        visibility: hidden;
        pointer-events: none;
    }
    &.menu-open .dropdown-button { visibility: visible; pointer-events: auto; }
    /* Don't apply :hover on iOS, since it triggers extremely broken click handling. */
    @media (hover: hover) {
        &:hover .dropdown-button { visibility: visible; pointer-events: auto; }
    }
    @media (hover: none) {
        &.selected .dropdown-button { visibility: visible; pointer-events: auto; }
    }

}

a {
    color: unset;
    text-decoration: unset;
}

.new-chat
{
    z-index: 1;
    position: sticky;
    top: 0;
}

.active-chat
{
    background-color: rgb(var(--v-theme-background-darken));
}

</style>
