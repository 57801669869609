// This manages a single chat.

import * as jsondiffpatch from "jsondiffpatch";
import Dexie from "dexie";

import settings, { SettingsLayer } from "@/misc/settings.js";
import ObjectDb from "@/misc/object-db.js";
import characterDb from "@/storytelling/characters.js";
import InstructTemplate from "@/ai/instruct-template.js";
import connection from "@/ai/connection.js";
import * as Util from "@/misc/util.js";

class ChatDb extends ObjectDb
{
    constructor()
    {
        super();
    }

    get entryClass() { return Chat; }

    getDb()
    {
        let db = new Dexie("chats");
        db.version(1).stores({
            chats: "++id, title, messages",
        });
        return db;
    }

    async getTable()
    {
        let db = await this.open();
        return db.chats;
    }
}

export const chatDb = new ChatDb();

export class Chat extends EventTarget
{
    constructor({
        id=undefined,
        title="New chat",
        messages=[],
        settings: settingsValues={},
        plot="",
        style="",
        characters=[],
    }={})
    {
        super();

        // This is set by chatDb when we're saved to the database.
        this._id = id;
        this._title = title;
        this._messages = [];
        this._settingsLayer = new ChatSettingsLayer({ chat: this, settings: settingsValues }); 
        this._plot = plot;
        this._style = style;
        this._characters = characters;

        this._setMessagesFromJson(messages);
        this._lastSavedUndoState = this._undoState;

        // Undo isn't saved.
        this._undo = [];
        this._redo = [];

        this._suspendUndo = false;

        // This message represents the system prompt, and is always the first message in the
        // message list.  It can't be deleted and its contents are saved as a setting, rather
        // than as a message.
        this._systemPromptMessage = new SystemMessage({ chat: this });

        // Most of the time when we're working with this chat, it'll be loaded as the primary chat
        // and settings.chatLayer will be set to our settingsLayer, so our settings will be applied.
        // However, we don't want to require that: we should be able to access the settings we'd be
        // using if we were active, whether we're actually active or not.
        //
        // This works the same as settings.value.settingName, except it gives the settings that we'd
        // be using if we were active.  For example, this.effectiveSettings.systemPrompt will give our
        // systemPrompt override if one is set, and fall back on earlier layers normally if not.
        this.effectiveSettings = new Proxy({}, {
            get: (target, name) => {
                // Temporarily apply our settings layer.
                let savedLayer = settings.chatLayer;
                settings.chatLayer = this.settingsLayer;
                try {
                    return settings.values[name];
                } finally {
                    settings.chatLayer = savedLayer;
                }
            },
            ownKeys: () => Array.from(this.allSettings.keys()),
            getOwnPropertyDescriptor: (target, name) => ({
                enumerable: true,
                configurable: true,
                value: target[name]
            }),
        });
    }

    // Return the user's system prompt for this chat.
    get _activeSystemPrompt() { return this.effectiveSettings.systemPrompt; }

    // Create a new empty chat.
    static defaultChat()
    {
        // Add an empty message to give the user somewhere to type by default.
        let chat = new Chat();
        chat.createNewMessage({ role: "user" });
        return chat;
    }

    copy() {
        let data = JSON.parse(JSON.stringify(this.toJSON()));
        delete data.id;
        return new Chat(data);
    }

    get plot() { return this._plot; }
    set plot(value) { this._plot = value; }
    get style() { return this._style; }
    set style(value) { this._style = value; }
    get characters() { return this._characters; }
    set characters(value) { this._characters = value; }
    get undo() { return this._undo; }
    set undo(value) { this._undo = value; }
    get redo() { return this._redo; }
    set redo(value) { this._redo = value; }

    // Saving to undo will be delayed while this is set to true.
    get suspendUndo() { return this._suspendUndo; }
    set suspendUndo(value)
    {
        this._suspendUndo = value;
        if(!value)
            this.storeUndo();
    }

    // ongoing is true if a message is incomplete and can be resumed.  This is stored on each
    // message, but we only make changes to the most recent message's status.
    get ongoing() { return this.latestMessage?.ongoing ?? false; }
    set ongoing(value)
    {
        console.assert(typeof(value) == "boolean", value);
        
        let latestMessage = this.latestMessage;
        if(!latestMessage)
            return;

        if(latestMessage.ongoing == value)
            return;

        latestMessage.ongoing = value;
    }

    _dispatchMessageChanged(message)
    {
        let e = new Event("message-changed");
        e.message = message;
        this.dispatchEvent(e);
    }

    toJSON()
    {
        // Note that we save this._messages,  not this.messages, which contains
        // the proxy for the system prompt.
        //
        // topTokens is saved with undo, but don't save it to the database.
        let messages = this._getMessagesJson({ includeTopTokens: false });

        let settings = this._settingsLayer.toJSON();
        let { id, title, plot, style, characters } = this;
        return {
            id,
            title,
            messages,
            settings,
            plot,
            style,
            characters,
        }
    }

    // Serialize the messages array.
    _getMessagesJson({includeTopTokens=true}={})
    {
        let messages = [];
        for(let message of this._messages)
            messages.push(message.toJSON({ includeTopTokens }));
        return messages;
    }

    _setMessagesFromJson(messages)
    {
        this._messages = [];
        for(let message of messages)
            this._messages.push(new ChatMessage({ chat: this, ...message}));
    }

    get id() { return this._id; }
    set id(value) { this._id = value; }
    set title(value) { this._title = value; }
    get title() { return this._title; }
    
    // The live message list includes the dynamic system prompt message.
    get messages()
    {
        return [
            this._systemPromptMessage,
            ...this._messages,
        ];
    }
    get settingsLayer() { return this._settingsLayer; }

    // Add a new message to the end of the chat.
    createNewMessage({
        role,
        content="",
        ongoing=false,
        position="end", // "start", "end", "before" or "after"
        relativeTo=null,
    }={})
    {
        // If this message is being inserted relative to the system prompt message, which
        // isn't in this._messages, add at the beginning.
        if(relativeTo === this._systemPromptMessage && (position == "before" || position == "after"))
        {
            relativeTo = null;
            position = "start";
        }

        let message = new ChatMessage({role, content, ongoing, chat: this});
        if(position == "start")
            this._messages.unshift(message);
        else if(position == "end")
            this._messages.push(message);
        else
        {
            let idx = this._messages.indexOf(relativeTo);
            if(idx == -1)
                throw new Error("relativeTo message not found");
            if(position == "before")
                this._messages.splice(idx, 0, message);
            else if(position == "after")
                this._messages.splice(idx+1, 0, message);
        }

        this.dispatchEvent(new Event("messages-changed"));

        return message;
    }
    
    removeMessage(message)
    {
        let idx = this._messages.indexOf(message);
        if(idx == -1)
            throw new Error("message not found");
        this._messages.splice(idx, 1);
        this.dispatchEvent(new Event("messages-changed"));
    }

    removeFromMessage(message)
    {
        if(message == null)
            return;

        let idx = this._messages.indexOf(message);
        if(idx == -1)
            throw new Error("message not found");
        this._messages.splice(idx, this._messages.length-idx);
        this.dispatchEvent(new Event("messages-changed"));
    }

    removeLastMessage()
    {
        this._messages.pop();
        this.dispatchEvent(new Event("messages-changed"));
    }

    // Return the most recent message, if any.
    get latestMessage() { return this.messages[this.messages.length-1]; }

    get systemPrompt()
    {
        let systemPrompt = this._activeSystemPrompt;

        // If we're in DreamGen mode, add the plot to the system prompt.
        if(InstructTemplate.isDreamGen)
        {
            let plotDescription = this.plot;
            if(plotDescription.length > 0) {
                systemPrompt += `
## Overall plot description:

${plotDescription.trim()}`;
            }

            let charactersId = this.characters;
            if(charactersId.length > 0) {
                systemPrompt += `

## Characters:`;
                for(let characterId of charactersId)
                {
                    let character = characterDb.getById(characterId);
                    if(character.description.length > 0) {
                        systemPrompt += `

### ${character.name}

${character.description.trim()}`;
                    }
                }
            }

            let styleDescription = this.style;
            if(styleDescription.length > 0) {
                systemPrompt += `

## Style description:

${styleDescription.trim()}`;
            }
        }

        return systemPrompt;
    }

    // Get the list of messages to include in the prompt, wrapped in their instruction format.
    _getMessageInfo()
    {
        let instructTemplate = InstructTemplate.getActiveTemplate();

        let messageInfo = [];
        let messagesWithSystemPrompt = this.messages;
        for(let i = 0; i < messagesWithSystemPrompt.length; ++i)
        {
            let message = messagesWithSystemPrompt[i];
            let { content, role } = message;

            let text = "";
            if(role == "system")          text += instructTemplate.systemPrefix;
            else if(role == "user")       text += instructTemplate.userPrefix;
            else                          text += instructTemplate.assistantPrefix;

            text += content;

            // If this is the last message and it's incomplete, omit the suffix, to allow
            // this message to conotinue.
            if(i < messagesWithSystemPrompt.length-1 || !this.ongoing)
            {
                if(role == "system")          text += instructTemplate.systemSuffix;
                else if(role == "user")       text += instructTemplate.userSuffix;
                else                          text += instructTemplate.assistantSuffix;
            }
            
            messageInfo.push({
                message,
                role,
                text,
            });
        }
        return messageInfo;
    }

    // Get the list of messages using DreamGen's format.
    _getMessageInfoDreamGen()
    {
        let messageInfo = [];
        let messagesWithSystemPrompt = this.messages;
        for(let i = 0; i < messagesWithSystemPrompt.length; ++i)
        {
            let message = messagesWithSystemPrompt[i];
            let { content, role } = message;

            let text = "<|im_start|>";
            if(role == "text?")
            {
                if(i < messagesWithSystemPrompt.length-1)
                {
                    console.log("Warning: text? must be the last message");
                    continue;
                }

                if(content.length > 0)
                {
                    console.log("Warning: text? must not contain content");
                    continue;
                }
                text += "text";
            }
            else
            {
                text += role;
                text += "\n";
                text += content;

                // If this is the last message and it's incomplete, omit the suffix, to allow
                // this message to conotinue.
                if(i < messagesWithSystemPrompt.length-1 || !this.ongoing)
                {
                    text += "<|im_end|>\n";
                }
            }

            messageInfo.push({
                message,
                role,
                text,
            });
        }
        return messageInfo;
    }

    // Return a prompt for generation.  This only does redumentary prompt truncation.
    //
    // Return:
    // prompt: The generated prompt.
    // tokensRemaining: The number of tokens left for the response.
    async getPrompt(backend, {
        // The "purged" field will be set on messages that weren't included in the prompt.
        // Set trackPurged=false to disable this.
        trackPurged=true,
    }={})
    {
        let instructTemplate = InstructTemplate.getActiveTemplate();
        let dreamGen = instructTemplate.dreamGen;

        // Make a list of all messages, with their instruct prefixes and suffixes
        // added.
        let messageInfo;
        if(dreamGen)
            messageInfo = this._getMessageInfoDreamGen();
        else
            messageInfo = this._getMessageInfo();

        // We always have one system prompt.
        console.assert(messageInfo.length > 0);

        // Look up the token count for each message.
        await this._getTokenCountForMessages(backend, messageInfo);

        if(trackPurged)
        {
            for(let info of messageInfo)
                info.message.purged = true;
        }

        // Trim down to the context size, giving room for the max output size.
        let availableContext = settings.values.contextSize;
        // console.log("Total context:", availableContext);

        // Add the system prompt.
        let promptPrefix = "";
        {
            let info = messageInfo.shift();
            let { role, text, tokenCount } = info;
            console.assert(role == "system")

            // Stop if we don't have enough context to fit the system prompt and at least one token.
            if(tokenCount+1 > availableContext)
                return { error: `System prompt is too large for the context size` };
            
            availableContext -= tokenCount;
            promptPrefix += text;
            info.message.purged = false;
        }

        // console.log("Total context after system prompt:", availableContext);

        // Add messages starting at the end and working backwards.
        let promptSuffix = "";
        function addMessage(info)
        {
            let { text, tokenCount } = info;

            // Stop if this would put us over the context size.
            if(tokenCount > availableContext)
                return false;

            availableContext -= tokenCount;
            promptSuffix = text + promptSuffix;
            info.message.purged = false;
            return true;
        }

        // The last message contains the instruct prefix (eg. <|start_header_id|>assistant<|end_header_id|>".
        // Always add it.  If we don't have enough space to fit it and the system prompt, return an error.
        if(messageInfo.length > 1)
        {
            let info = messageInfo.pop();
            if(!addMessage(info))
                return { error: "No messages fit in the prompt.  Check your context size." };

            // console.log("Total context after last message:", availableContext);
        }

        // Reserve the output length.  If this isn't positive, we won't add any more messages.
        availableContext -= settings.values.maxOutputLength;

        // console.log("Total context after output length:", availableContext);

        // Add messages, starting from the end and working backwards until we run
        // out of tokens.
        while(messageInfo.length > 0)
        {
            let info = messageInfo.pop();
            if(!addMessage(info))
                break;
        }

        let prompt = promptPrefix + promptSuffix;

        // Double-check the token count.  There might be fewer tokens, but if there
        // are more tokens than we expect then something is wrong.
        let actualTokenCount = await backend.getTokenCount(prompt);
        if(actualTokenCount > settings.values.contextSize)
            console.warn(`Warning: prompt token count of ${actualTokenCount} is greater than expected ${settings.values.contextSize}`);

        // Unreserve the output length.
        availableContext += settings.values.maxOutputLength;

        // Clamp to the number of tokens the user actually wants.
        availableContext = Math.min(availableContext, settings.values.maxOutputLength);

        return { prompt, availableContext };
    }

    async _getTokenCountForMessages(backend, messageInfo)
    {
        // We cache token counts per model.  The API doesn't include the model name in the
        // response, so we assume the model won't change between us asking for the model
        // name and getting the token count.  This cache is per-message, so if we amend
        // a message, we won't reuse its cache.  For now we don't purge this cache.
        let modelName = await backend.getModelName();
        this._tokenCountCachePerModel ??= new Map();
        let tokenCountCache = this._tokenCountCachePerModel.get(modelName);
        if(tokenCountCache == null)
        {
            tokenCountCache = new Map();
            this._tokenCountCachePerModel.set(modelName, tokenCountCache);
        }

        // Look up the token counts for each message.  Run each in parallel to avoid
        // waiting on round-trips for each message, since this is very fast server-side.
        let promises = [];
        for(let info of messageInfo)
        {
            let cachedTokenCount = tokenCountCache.get(info.text);
            if(cachedTokenCount != null)
            {
                info.tokenCount = cachedTokenCount;
                continue;
            }

            let promise = (async() => {
                info.tokenCount = await backend.getTokenCount(info.text);
            })();
            promises.push(promise);
        }
        await Promise.all(promises);

        // Cache the token counts.
        for(let info of messageInfo)
            tokenCountCache.set(info.text, info.tokenCount);
    }

    // Clear topTokens on all messages.
    clearAllTopTokens()
    {
        for(let message of this.messages)
            message.clearTopTokens();
    }

    async save({
        // If true, store any changes made since the last save in the undo stack.
        storeUndo=true
    }={})
    {
        if(storeUndo)
            this.storeUndo();

        await chatDb.save(this);
        this.dispatchEvent(new Event("saved"));
    }

    // Return true if storeUndo has been called on the current state, and it's safe to
    // undo.
    _isUndoSynced()
    {
        return JSON.stringify(this._lastSavedUndoState) == JSON.stringify(this._undoState);
    }

    // Return the state that we save and restore with undo.
    get _undoState()
    {
        let data = {
            systemPrompt: this._settingsLayer.get("systemPrompt"),
            messages: this._getMessagesJson(),
        };
        return data;
    }

    set _undoState(state)
    {
        let data = state;
        let { systemPrompt, messages } = data;
        this._settingsLayer.set("systemPrompt", systemPrompt);
        this._setMessagesFromJson(messages);
    }

    // Save the current state to the undo stack.
    //
    // This used to use fast-myers-diff to store a delta, but that became very slow
    // when topTokens was added.  Currently we just store the whole state each time.
    // Is there a diff algorithm that's more suited to this, which would store deltas
    // for the big chunk of the conversation that doesn't change from turn to turn,
    // but only replacing whole messages instead of trying to find a delta blindly?
    storeUndo()
    {
        // Don't store undo while suspendUndo is enabled.
        if(this._suspendUndo)
            return;

        let oldState = this._lastSavedUndoState;
        let newState = this._undoState;
        this._lastSavedUndoState = newState;

        let undoDelta = jsondiffpatch.diff(newState, oldState);
        if(undoDelta == null)
            return;

        this._undo.push(undoDelta);
        this._redo = [];

        this.dispatchEvent(new Event("undo-changed"));
    }

    get canUndo() { return this._undo.length > 0; }
    get canRedo() { return this._redo.length > 0; }
    undo()
    {
        if(!this._isUndoSynced())
            throw new Error("Undo stack isn't saved");

        if(!this.canUndo)
            return;

        let undoDelta = this._undo.pop();
        let state = this._undoState;
        jsondiffpatch.patch(state, undoDelta);

        this._lastSavedUndoState = state;
        this._undoState = state;
        this._redo.push(undoDelta);
        this.save({ storeUndo: false });

        this.dispatchEvent(new Event("messages-changed"));
        this.dispatchEvent(new Event("undo-changed"));
    }

    redo()
    {
        if(!this._isUndoSynced())
            throw new Error("Undo stack isn't saved");

        if(!this.canRedo)
            return;

        let undoDelta = this._redo.pop();
        let state = this._undoState;
        let redoDelta = jsondiffpatch.reverse(undoDelta);

        jsondiffpatch.patch(state, redoDelta);

        this._lastSavedUndoState = state;
        this._undoState = state;
        this._undo.push(undoDelta);
        this.save({ storeUndo: false });

        this.dispatchEvent(new Event("messages-changed"));
        this.dispatchEvent(new Event("undo-changed"));
    }

    // Ask the model to create a title for the current chat.
    //
    // This requires that Kobold be in multi-user mode.  We don't have any guards to prevent the user
    // from starting a generation while this is running.  This is usually fine, since we're using the
    // same prompt as the user.  Since this is requesting a very short response, most of the time will
    // be prompt processing, which is performing the same work that will be needed for the user's next
    // request anyway.
    async createTitle({
        force=false,
    }={})
    {
        if(!force && (this.title != "New chat" || !settings.values.autoTitlePrompt))
            return;

        console.log(`Creating messsage title`);

        // Make a copy of the chat that we can add our request to.
        let temporaryChat = this.copy();
        
        temporaryChat.createNewMessage({
            role: "user",
            content: settings.values.autoTitlePrompt,
        });

        // Create the assistant message for it to reply into.
        temporaryChat.createNewMessage({
            role: "assistant",
            content: '"',
            ongoing: true,
        });
        
        let backend = connection.getBackend();

        // Get the prompt.
        let { prompt, availableContext, error } = await temporaryChat.getPrompt(backend, {
            // Don't clobber the purged field in this background request.
            trackPurged: false,
        });
        if(error)
        {
            console.log(`Title generation failed: ${error}`);
            return;
        }

        let abort = new AbortController();
        let { signal } = abort;

        let settingsOverrides = {
            // Limit the output length.  If it takes longer than this, it's probably not
            // giving a useful title anyway.  Lower the temperature to keep the results
            // simple.
            maxOutputLength: 20,
            contextSize: 256,
            temperature: 0.8,
        };

        let generation = backend.generate({
            prompt,
            signal,
            availableContext,
            settingsOverrides,
        });

        for await(let { token } of generation)
            temporaryChat.latestMessage.content += token;
    
        // Look for "Title".  If it's a sentence ("Title."), discard the period.  Ignore anything
        // after the final quote.
        let result = temporaryChat.latestMessage.content;
        let match = result.match(/"([^"]+?)\.?".*/);
        if(!match)
        {
            console.log(`Title generation request failed, output was: ${result}`);
            return null;
        }
        this.title = match[1];
        this.save();
    }
}

// Chats can override settings.  ChatSettingsLayer is a settings layer which saves
// settings to the chat.
class ChatSettingsLayer extends SettingsLayer
{
    constructor({
        chat,
        settings={},
    }={})
    {
        super({ settings });
        this._chat = chat;
    }

    // Load a named settings layer.
    //
    // The chat layer is stored in the saved chat.
    static load(name)
    {
        // If this doesn't exist, we'll create a new empty layer.
        let data = localStorage.getItem(`settings-${name}`);
        let json = data? JSON.parse(data):undefined;
        return new ChatSettingsLayer(name, json);
    }

    // Saving settings just saves the whole chat.
    save()
    {
        this._chat.save();
    }
}

// A single message in chat.
export class ChatMessage
{
    constructor({
        role,
        content,
        ongoing=false,
        purged=false,
        topTokens=null,

        // The chat we're contained in.  We'll send message-changed on this chat
        // when we're changed.
        chat=null,
    }={})
    {
        this._role = role;
        this._content = content;
        this._ongoing = ongoing;
        this._purged = purged;

        // These aren't saved with the chat.
        this._editing = false;
        this._chat = chat;

        // If the backend is giving us logprobs, this keeps track of alternative tokens.
        // Each key is "position/text", where position is an index into this.content and
        // text is the text at that position.
        this._topTokens = new Map();
        if(topTokens != null)
        {
            for(let [offset, value] of Object.entries(topTokens))
            {
                offset = parseInt(offset);
                this._topTokens.set(offset, value);
            }
        }
    }

    get role() { return this._role; }
    set role(value) { this._setProperty("_role", value); }
    get content() { return this._content; }
    set content(value) { this._setProperty("_content", value); }
    get ongoing() { return this._ongoing; }
    set ongoing(value) { this._setProperty("_ongoing", value); }
    get purged() { return this._purged; }
    set purged(value) { this._setProperty("_purged", value); }
    get editing() { return this._editing; }
    set editing(value) { this._setProperty("_editing", value); }
    get topTokens() { return this._topTokens; }

    appendContent({ token, topTokens })
    {
        if(topTokens)
        {
            let key = this.content.length;
            this._topTokens.set(key, {token, topTokens});
        }

        this.content += token;
    }

    // Append a list of { token, topToken }.
    //
    // This is equivalent to calling appendContent repeatedly, but will only trigger a
    // single modification.
    appendContentBatch(data)
    {
        let contentToAppend = "";
        for(let { token, topTokens } of data)
        {
            let key = this.content.length + contentToAppend.length;
            contentToAppend += token;
            this._topTokens.set(key, {token, topTokens});
        }

        this.content += contentToAppend;
    }

    clearTopTokens()
    {
        this._topTokens.clear();
    }

    // Return true if this is an assistant message.
    //
    // The role for these is usually "assistant", but it can be an arbitrary value
    // when DreamGen is active, so check that this isn't a system or user message.
    get isAssistantMessage()
    {
        return this.role != "user" && this.role != "system";
    }

    _setProperty(key, value)
    {
        if(value == this[key])
            return;
        this[key] = value;
        this._modified();
    }

    _modified()
    {
        if(this._chat)
            this._chat._dispatchMessageChanged(this);
    }

    // If includeTopTokens is false, don't serialize topTokens.  This is used for saving
    // to the database.
    toJSON({includeTopTokens=true}={})
    {
        let { role, content, ongoing, purged, topTokens } = this;
        let data = {
            role,
            content,
            ongoing,
            purged,
        };

        if(includeTopTokens)
            data.topTokens = Object.fromEntries(topTokens);

        return data;
    }
}

class SystemMessage extends ChatMessage
{
    constructor(args)
    {
        super({
            ...args,
            role: "system",
        });
    }

    get content()
    {
        return this._chat._activeSystemPrompt;
    }

    set content(value)
    {
        // Save the system prompt to the settings layer.  Skip this if we haven't
        // overridden the prompt already and the value doesn't change it, so we
        // don't write the default prompt to every chat if it isn't modified.
        if(!this._chat.settingsLayer.has("systemPrompt") &&
            value == this._chat._activeSystemPrompt)
        {
            console.log("No change to system prompt");
            return true;
        }

        this._chat._settingsLayer.set("systemPrompt", value);
        this._modified();
    }

    get role() { return "system"; }
    set role(value) { throw new Error("Can't modify the system prompt role"); }
}

